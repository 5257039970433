import { z } from "zod";

const UserInfoSchema = z.object({
  id: z.number(),
  info: z.string(),
  name: z.string(),
  phone: z.string().optional(),
  signCheck: z.boolean().optional(),
  link: z.string().optional(),
  image: z.string().optional().nullable(),
  afterToken: z.string(),
});

type UserInfo = z.infer<typeof UserInfoSchema>;

type ClientUserInfo = Omit<
  UserInfo,
  "phone" | "signCheck" | "link" | "image" | "afterToken"
>;

interface LawyerUserInfo extends ClientUserInfo {
  phone: string;
  signCheck: boolean;
  link: string;
  image: string;
}

export { UserInfo, ClientUserInfo, LawyerUserInfo, UserInfoSchema };
