import { copyToClipboard } from "./copyToClipboard";
import { openSuccessAlert } from "./modal/openModal";

function inAppBrowserOut() {
  copyToClipboard(window.location.href);
  // eslint-disable-next-line no-alert
  openSuccessAlert(
    `URL주소가 복사되었습니다.\n\nSafari가 열리면 주소창을 길게 터치한 뒤, "붙여놓기 및 이동"를 누르면 정상적으로 이용하실 수 있습니다.`,
    () => {
      window.location.href = "x-web-search://?";
    }
  );
}

function alertEscapeInAppBrowser() {
  // 모바일대응뷰포트강제설정
  const mobile = document.createElement("meta");

  mobile.name = "viewport";
  mobile.content =
    "width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, minimal-ui";

  document.getElementsByTagName("head")[0].appendChild(mobile);
  // 노토산스폰트강제설정
  const fonts = document.createElement("link");

  fonts.href =
    "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap";

  document.getElementsByTagName("head")[0].appendChild(fonts);

  const styleElement = document.createElement("style");
  const h2Element = document.createElement("h2");
  const articleElement = document.createElement("article");
  const buttonElement = document.createElement("button");
  const imgElement = document.createElement("img");

  styleElement.innerHTML = `body{margin:0;padding:0;font-family: 'Noto Sans KR', sans-serif;overflow: hidden;height: 100%;}h2{font-size:22px; padding-top:50px; padding-bottom:30px; text-align:center;font-family: 'Noto Sans KR', sans-serif;}article{font-size:17px; word-break:keep-all;color:#999;}`;
  h2Element.innerHTML = `인앱브라우저 호환문제로 인해<br />Safari로 접속해야합니다.`;

  articleElement.innerHTML = `아래 버튼을 눌러 Safari를 실행해주세요<br />Safari가 열리면, 주소창을 길게 터치한 뒤,<br />'붙여놓기 및 이동'을 누르면<br />정상적으로 이용할 수 있습니다.<br /><br />`;
  articleElement.style.textAlign = "center";
  articleElement.style.fontSize = "17px";
  articleElement.style.wordBreak = "keep-all";
  articleElement.style.color = "#999";

  buttonElement.innerHTML = `Safari로 열기`;
  buttonElement.style.minWidth = "180px";
  buttonElement.style.marginTop = "10px";
  buttonElement.style.height = "54px";
  buttonElement.style.fontWeight = "700";
  buttonElement.style.backgroundColor = "#31408E";
  buttonElement.style.color = "#fff";
  buttonElement.style.borderRadius = "4px";
  buttonElement.style.border = "none";
  buttonElement.style.outline = "none";
  buttonElement.style.cursor = "pointer";
  buttonElement.style.marginLeft = "calc(50% - 90px)";
  buttonElement.style.marginRight = "calc(50% - 90px)";
  buttonElement.style.fontSize = "17px";
  buttonElement.style.lineHeight = "54px";
  buttonElement.style.textAlign = "center";

  buttonElement.addEventListener("click", () => inAppBrowserOut());

  imgElement.src =
    "https://tistory3.daumcdn.net/tistory/1893869/skin/images/inappbrowserout.jpeg";
  imgElement.style.width = "70%";
  imgElement.style.marginTop = "30px";
  imgElement.style.marginLeft = "15%";
  imgElement.style.marginRight = "15%";

  document.body.innerHTML = "";
  document.body.appendChild(styleElement);
  document.body.appendChild(h2Element);
  articleElement.appendChild(buttonElement);
  document.body.appendChild(articleElement);
  document.body.appendChild(imgElement);
}

function moveToChromeBrowser() {
  window.location.href = `intent://${window.location.href.replace(
    /https?:\/\//i,
    ""
  )}#Intent;scheme=http;package=com.android.chrome;end`;
}

export function escapeInAppBrowser() {
  if (
    navigator.userAgent.match(
      /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i
    )
  ) {
    if (navigator.userAgent.match(/iPhone|iPad/i)) {
      alertEscapeInAppBrowser();
    }
    // else {
    //   moveToChromeBrowser();
    // }
  }
}
