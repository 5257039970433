import styles from "../Modals.module.scss";

interface XIconProps {
  className?: string;
}

const XIcon = ({ className = "text-white" }: XIconProps) => (
  <div className={styles.xIconBox}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={`${styles.xIcon} ${className}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
);

export default XIcon;
