import { useEffect, useMemo } from "react";
import { createBrowserHistory } from "history";
import AuthToken from "@lawsdaq-util/authToken";
import { useGetUser } from "@lawsdaq-hook/useUserInfo";
import { escapeInAppBrowser } from "@lawsdaq-util/escapeInAppBrowser";
import RootRouter from "./router";
import LegacyCompatibility from "@lawsdaq-lib/legacy-compatibility";
import ReactGA from "react-ga";
import { Position } from "@lawsdaq-lib/set-position-children";
import ScrollToTop from "@lawsdaq-util/scrollToTop";
import BtnToTop from "@lawsdaq-util/btnToTop";

import ModalContainer from "./components/modal-container";
import SignIn from "./components/sign-in";
import { SocketProvider } from "@lawsdaq-lib/socket-provider";

export const history = createBrowserHistory();

const App = () => {
  const user = useGetUser();
  // const gaTrackingId = String(process.env.REACT_APP_GA_TRACKING_ID);

  const { nickname, serverPath } = useMemo(
    () => ({
      nickname: user?.nickname,
      serverPath: AuthToken.getAppURL(),
    }),
    [user]
  );

  const isSignInActive = useMemo(() => {
    const accessToken = AuthToken.getAccessToken();
    const refreshToken = AuthToken.getRefreshToken();

    if (accessToken !== undefined || refreshToken !== undefined) {
      return true;
    }

    return false;
  }, []);

  useEffect(() => {
    escapeInAppBrowser();
    // 구글 애널리틱스
    ReactGA.initialize("UA-220881593-1");
    // ReactGA.initialize(gaTrackingId);
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
      // ReactGA.send("pageview");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="app" className="app">
      <div id="portal" />
      <SignIn isActive={isSignInActive} />
      <LegacyCompatibility />
      <SocketProvider serverPath={serverPath} nickname={nickname}>
        <RootRouter>
          <Position.Top>
            <ScrollToTop />
            {/* <BtnToTop /> */}
          </Position.Top>
          <Position.Bottom>
            <ModalContainer />
          </Position.Bottom>
        </RootRouter>
      </SocketProvider>
    </div>
  );
};

export default App;
