import { useQuery } from "@tanstack/react-query";
import { getUserInfoAPI } from "@lawsdaq-service/user";
import { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import { useEffect, useRef } from "react";
import { UserInfo } from "@lawsdaq-schema/userInfo";
import { useGetUser, useSignIn } from "@lawsdaq-hook/useUserInfo";

export function useGetUserInfo(errorCallback?: APIErrorCallback) {
  return useQuery(["userInfo"], () => {
    return getUserInfoAPI(errorCallback);
  });
}

interface SignInProps {
  successCallback?: () => void;
  errorCallback?: APIErrorCallback;
}

const SignIn = ({ successCallback, errorCallback }: SignInProps) => {
  // 서버에서 유저 정보 가져오기
  const { data: userInfo } = useGetUserInfo(errorCallback); // 사용자 인증 api (서버에 토큰 만료 여부 요청)
  // console.log("userInfo", userInfo);

  const userInfoRef = useRef<UserInfo | null | undefined>(userInfo);

  // 유저정보 api에서 가져온  정보 리덕스에 전달
  const actionSignInUser = useSignIn();

  useEffect(() => {
    if (userInfo && userInfoRef.current !== userInfo) {
      userInfoRef.current = userInfo;
      actionSignInUser(userInfo);
      successCallback && successCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return null;
};

interface SignInWrapperProps extends SignInProps {
  isActive?: boolean;
}

const SignInWrapper = ({
  isActive = true, // 토근  상태 확인
  ...restProps
}: SignInWrapperProps) => {
  const user = useGetUser(); // 토큰이 만료이면 null로 나온다

  // console.log("isActive", isActive);
  // console.log("user", user);

  // 로그아웃 상태 및 user 로그인 되어있으면 불러올 필요가 없으니 null로 리턴
  if (!isActive || user) {
    return null;
  }

  // 로그인 하는데 토큰 없을때 > 카톡,네이버 api 로그인 성공하면 > SignIn 컴포넌트 쓰임
  // 토큰이 있는데 (즉 로그인 상태에서 user정보가 없을때 ) 새로고침에서 사용
  return <SignIn {...restProps} />;
  // return null;
};

export default SignInWrapper;
