import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const stockSlice = createSlice({
  name: "stocks", // 리듀서 이름
  initialState: {
    id: undefined,
    info: "",
    email: "",
  },
  reducers: {
    loginStock(state: any, action: any) {
      return action.payload;
    },
  },
});

export const { loginStock }: any = stockSlice.actions;
export default stockSlice.reducer;
