import { ModalProvider, ModalMeta } from "@lawsdaq-lib/modal";
import RegisterService from "./components/RegisterService";

const modalMetaList: ModalMeta[] = [
  {
    name: "registerService",
    component: RegisterService,
    defaultOptions: {
      required: true,
      closeDelay: 700,
    },
  },
];

const ModalContainer = () => {
  return <ModalProvider modalMeta={modalMetaList} />;
};

export default ModalContainer;
