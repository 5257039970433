import { ElementType, HTMLAttributes, useMemo } from "react";
import ModalTitle from "../../components/ModalTitle";
import ModalCancelButton from "../../components/ModalCancelButton";
import ModalDigCancelBtn from "@lawsdaq-lib/modal/components/ModalDigCancelBtn";
import ModalContent from "../../components/ModalContent";
import ModalConfirmButton from "../../components/ModalConfirmButton";
import ModalDigConfirmBtn from "@lawsdaq-lib/modal/components/ModalDigConfirmBtn";
import ModalSubButton from "../../components/ModalSubButton";
import XIcon from "./XIcon";
import ModalButton from "../../components/ModalButton";
import useModalOptions from "../../hooks/useModalOptions";
import ModalSubTitle from "../../components/ModalSubTitle";
import { ModalConfirmType } from "../../services/modalStateManager";

import styles from "../Modals.module.scss";
import classNames from "classnames";

function mergeClassNames(
  defaultClassName: string,
  className: string | undefined | ((defaultClassName: string) => string)
) {
  if (typeof className === "function") {
    return className(defaultClassName);
  }

  if (className) {
    return `${defaultClassName} ${className}`;
  }

  return defaultClassName;
}

interface CommonProps<T extends Element>
  extends Omit<HTMLAttributes<T>, "className"> {
  className?: string | ((defaultClassName: string) => string);
}

interface ColorProps {
  color?: "black" | "white";
}

const Header = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div {...restProps} className={mergeClassNames(styles.header, className)}>
      {children}
    </div>
  );
};

const HeaderLeft = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div
      {...restProps}
      className={mergeClassNames(styles.headerLeft, className)}
    >
      {children}
    </div>
  );
};

const Tag = ({
  className,
  color = "white",
  children,
  ...restProps
}: CommonProps<HTMLDivElement> & ColorProps) => (
  <ModalSubTitle
    {...restProps}
    className={mergeClassNames(
      classNames(
        "overflow-hidden whitespace-nowrap flex justify-center items-center mr-1.5 text-center px-2 min-w-5.6 text-sm font-medium h-3.4 leading-3.3",
        "lg:font-bold lg:text-base lg:leading-3.8 lg:h-4 rounded-[4rem]",
        {
          "bg-black text-white": color === "black",
          "bg-white text-lawsdaq-red": color === "white",
        }
      ),
      className
    )}
  >
    {children}
  </ModalSubTitle>
);

const CircleIconBox = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div
      {...restProps}
      className={mergeClassNames(styles.circleIconBox, className)}
    >
      {children}
    </div>
  );
};

const Title = ({
  className,
  color,
  children,
  ...restProps
}: CommonProps<HTMLHeadingElement> & ColorProps) => {
  const defaultClassName =
    color === "black"
      ? `${styles.title} text-black`
      : `${styles.title} text-white`;

  return (
    <ModalTitle
      {...restProps}
      className={mergeClassNames(defaultClassName, className)}
    >
      {children}
    </ModalTitle>
  );
};

const HeaderRightWithCancelButton = ({
  className,
  color,
  ...restProps
}: CommonProps<HTMLDivElement> & ColorProps) => {
  const options = useModalOptions();

  if (!options) {
    return null;
  }

  const { action } = options;

  const onClickCancel = () => {
    action(false);
  };

  return (
    <div
      {...restProps}
      className={mergeClassNames(styles.headerRight, className)}
    >
      <button
        type="button"
        className={styles.cancelBtn}
        onClick={onClickCancel}
      >
        <XIcon className={color === "black" ? "text-black" : "text-white"} />
      </button>
    </div>
  );
};

const Main = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div {...restProps} className={mergeClassNames(styles.main, className)}>
      {children}
    </div>
  );
};

const Info = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLParagraphElement>) => {
  return (
    <p {...restProps} className={mergeClassNames(styles.info, className)}>
      {children}
    </p>
  );
};

const InfoContent = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement> & {
  as?: ElementType;
}) => {
  return (
    <ModalContent
      {...restProps}
      className={mergeClassNames(styles.info, className)}
    >
      {children}
    </ModalContent>
  );
};

const Question = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLParagraphElement>) => {
  return (
    <p {...restProps} className={mergeClassNames(styles.question, className)}>
      {children}
    </p>
  );
};

const QuestionContent = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <ModalContent
      {...restProps}
      className={mergeClassNames(styles.question, className)}
    >
      {children}
    </ModalContent>
  );
};

const Footer = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div {...restProps} className={mergeClassNames(styles.footer, className)}>
      {children}
    </div>
  );
};

const CancelButton = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLButtonElement>) => {
  return (
    <ModalCancelButton
      {...restProps}
      className={mergeClassNames(styles.closeBtn, className)}
    >
      {children}
    </ModalCancelButton>
  );
};
const DigCancelBtn = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLButtonElement>) => {
  return (
    <ModalDigCancelBtn
      {...restProps}
      className={mergeClassNames(styles.closeBtn, className)}
    >
      {children}
    </ModalDigCancelBtn>
  );
};

const ConfirmButton = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLButtonElement>) => {
  return (
    <ModalConfirmButton
      {...restProps}
      className={mergeClassNames(
        `${styles.closeBtn} ${styles.confirmBtn}`,
        className
      )}
    >
      {children}
    </ModalConfirmButton>
  );
};
const ConfirmDigBtn = ({
  className,
  children,
  ...restProps
}: CommonProps<HTMLButtonElement>) => {
  return (
    <ModalDigConfirmBtn
      {...restProps}
      className={mergeClassNames(
        `${styles.closeBtn} ${styles.confirmBtn}`,
        className
      )}
    >
      {children}
    </ModalDigConfirmBtn>
  );
};

interface SubButtonProps extends CommonProps<HTMLButtonElement> {
  confirmType: ModalConfirmType;
}

const SubButton = ({ className, children, ...restProps }: SubButtonProps) => {
  return (
    <ModalSubButton
      {...restProps}
      className={mergeClassNames(
        `${styles.closeBtn} ${styles.subBtn}`,
        className
      )}
    >
      {children}
    </ModalSubButton>
  );
};

interface AsyncButtonProps extends CommonProps<HTMLButtonElement> {
  duration?: number;
  confirmType: ModalConfirmType;
  setCallbackResult?: (result?: boolean) => void;
}

const Button = ({
  className,
  confirmType,
  children,
  ...restProps
}: AsyncButtonProps) => {
  const btnType = (() => {
    switch (confirmType) {
      case true:
        return styles.confirmBtn;
      case false:
        return styles.cancelBtn;
      default:
        return styles.subBtn;
    }
  })();

  return (
    <ModalButton
      {...restProps}
      confirmType={confirmType}
      className={mergeClassNames(`${styles.closeBtn} ${btnType}`, className)}
    >
      {children}
    </ModalButton>
  );
};

const MarginBox = ({
  className,
  ...restProps
}: CommonProps<HTMLDivElement>) => {
  return (
    <div
      {...restProps}
      className={mergeClassNames(styles.marginBox, className)}
    />
  );
};

interface ModalBaseProps extends CommonProps<HTMLDivElement> {
  type?:
    | "confirm"
    | "alert"
    | "warn"
    | "error"
    | "success"
    | "info"
    | "leave"
    | "dig"
    | "withdrawal";
  innerContainerClassName?: string | ((defaultClassName: string) => string);
}

const ModalBase = ({
  className,
  innerContainerClassName,
  children,
  type,
  ...restProps
}: ModalBaseProps) => {
  const modalType = useMemo(() => {
    switch (type) {
      case "confirm":
        return styles.confirm;
      case "alert":
        return styles.alert;
      case "warn":
        return styles.warn;
      case "error":
        return styles.error;
      case "success":
        return styles.success;
      case "info":
        return styles.info;
      case "leave":
        return styles.confirm;
      case "dig":
        return styles.confirm;
      case "withdrawal":
        return styles.confirm;
      default:
        return "";
    }
  }, [type]);

  return (
    <div
      {...restProps}
      className={mergeClassNames(`${styles.modal} ${modalType}`, className)}
    >
      <div
        className={mergeClassNames(
          styles.modalContainer,
          innerContainerClassName
        )}
      >
        {children}
      </div>
    </div>
  );
};

ModalBase.Header = Header;
ModalBase.HeaderLeft = HeaderLeft;
ModalBase.HeaderRightWithCancelButton = HeaderRightWithCancelButton;
ModalBase.Tag = Tag;
ModalBase.CircleIconBox = CircleIconBox;
ModalBase.Title = Title;
ModalBase.Main = Main;
ModalBase.Info = Info;
ModalBase.InfoContent = InfoContent;
ModalBase.Question = Question;
ModalBase.QuestionContent = QuestionContent;
ModalBase.Footer = Footer;
ModalBase.CancelButton = CancelButton;
ModalBase.CancelDigButton = DigCancelBtn;
ModalBase.ConfirmButton = ConfirmButton;
ModalBase.ConfirmDigButton = ConfirmDigBtn;
ModalBase.SubButton = SubButton;
ModalBase.Button = Button;
ModalBase.MarginBox = MarginBox;

export default ModalBase;
