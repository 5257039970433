import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import createURLSearchParams from "@lawsdaq-util/createURLSearchParams";
import { TokensSchema } from "@lawsdaq-schema/tokens";
import AuthToken from "@lawsdaq-util/authToken";

interface RegisterNaverProps {
  token: string;
  ad: boolean;
}

/**
 * naver 인증을 통해 얻은 토큰으로 accessToken과 refreshToken을 발급 받습니다.
 * token을 발급받으면 cookie에 저장합니다.
 * @param token
 * @param ad
 * @param errorCallback
 * @returns
 */
export async function registerNaverAPI(
  { token, ad }: RegisterNaverProps,
  errorCallback?: APIErrorCallback
) {
  // const data = createURLSearchParams({ token, ad: `${ad ? 1 : 0}` });
  const data = createURLSearchParams({ token });
  const datas = createURLSearchParams();
  // console.log("받아온 네이버 유저정보", token);
  // console.log("받아온 네이버 유저정보", ad);
  // console.log("datas", datas);

  const response = await axios.successfulPost({
    url: "/api/user/registerNaver",
    data,
    validate: TokensSchema.parse,
    errorCallback,
  });

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;

  AuthToken.setAccessToken(accessToken);
  AuthToken.setRefreshToken(refreshToken);

  return response;
}
