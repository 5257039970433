import axios from "axios";
import Cookies from "js-cookie";
import { ILawyerSignupData } from "TypeModule";

// const onlyLawyerInfoAPI = async (accessToken) => {
const OnlyLawyerInfoFieldUpdateAPI = async (
  dataList: any
  // lawyerIdx: string
) => {
  const accessToken = Cookies.get("accessToken");
  const params = new URLSearchParams();
  // params.append("lawyerIdx", lawyerIdx);
  params.append("majorField", JSON.stringify(dataList));

  const config = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      authorization: accessToken,
    },
  };

  return (
    axios
      // .post("/api/lawyerInfo/mainUpdate", params, config)
      .post("/api/lawyerInfo/majorFieldUpdate", params, config)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          alert("내 정보수정이 완료되었습니다.");
          return status;
        }
        return res;
      })
      .catch((e) => console.error(e))
  );
};

export default OnlyLawyerInfoFieldUpdateAPI;
