import { ReactNode, isValidElement, Children, Fragment } from "react";

interface Props {
  children: ReactNode;
}

const Top = ({ children }: Props) => {
  return <div>{children}</div>;
};

const Middle = ({ children }: Props) => {
  return <div>{children}</div>;
};

const Bottom = ({ children }: Props) => {
  return <div>{children}</div>;
};

/**
 * children을 Top, Middle, Bottom에 따라 분류해줍니다.
 * 분류된 children들을 원하는 위치에 배치하면 됩니다.
 * @param children
 * @returns
 */
function setPositionChildren(children?: ReactNode) {
  if (!children) {
    return {
      top: [],
      middle: [],
      bottom: [],
    };
  }

  const topChildren: ReactNode[] = [];
  const middleChildren: ReactNode[] = [];
  const bottomChildren: ReactNode[] = [];

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (!child.props.children) {
        return;
      }

      if (child.type === Top) {
        topChildren.push(child.props.children);

        return;
      }

      if (child.type === Middle) {
        middleChildren.push(child.props.children);

        return;
      }

      if (child.type === Bottom) {
        bottomChildren.push(child.props.children);

        return;
      }

      if (child.type === Fragment) {
        const { top, middle, bottom } = setPositionChildren(
          child.props.children
        );

        topChildren.push(top);
        middleChildren.push(middle);
        bottomChildren.push(bottom);
      }
    }
  });

  return {
    top: topChildren.flat(),
    middle: middleChildren.flat(),
    bottom: bottomChildren.flat(),
  };
}

/**
 * children이 배치될 위치를 명시해주는 컴포넌트입니다.
 */
const Position = {
  Top,
  Middle,
  Bottom,
};

export { Position, setPositionChildren };
