import axios, { AxiosRequestConfig } from "axios";
import validateAccessToken from "./validateAccessToken";
import AuthToken from "../authToken";
import { APIError } from "./apiError";
import ConvertDataToSpecificType from "../convert-data-to-specific-type/convertDataToSpecificType";

interface UrlProps {
  [p: string]: string | number | undefined;
}
interface SuccessfulApiProps<T extends (response: any) => any> {
  url: string;
  config?: AxiosRequestConfig;
  param?: string;
  query?: UrlProps;
  data?: any;
  applyHeaders?: boolean;
  token?: string | null;
  validate?: T;
  convertDataToSpecificType?: (data: ConvertDataToSpecificType) => any;
  errorCallback?: (error: APIError) => void;
  verifyErrorCondition?: (httpStatus: number) => boolean;
  skipResponseDataValidation?: boolean;
}

interface ValidateOption {
  verifyErrorCondition?: (status: number) => boolean;
  skipResponseDataValidation?: boolean;
}

function validateAxiosResponse(
  response: any,
  {
    verifyErrorCondition = (status) => {
      return status !== 200;
    },
    skipResponseDataValidation = false,
  }: ValidateOption = {}
) {
  if (
    !response ||
    typeof response !== "object" ||
    !response.status ||
    !response.data
  ) {
    throw new APIError("axios get request failed");
  }

  if (verifyErrorCondition(response.status)) {
    throw new APIError({ response });
  }

  const { data } = response;

  if (
    skipResponseDataValidation ||
    typeof data !== "object" ||
    !data.status ||
    !data.result ||
    typeof data.status !== "string"
  ) {
    return data;
  }

  return data.result;
}

/**
 *  LawsDaqAxios는 AccessToken의 재발급 기능을 추가한 axios wrapper module입니다.
 */
class LawsDaqAxios {
  // eslint-disable-next-line no-empty-function
  constructor(
    private ajax: typeof axios = axios,
    private defaultConfig: AxiosRequestConfig = {}
  ) {}

  getConfigBySetHeaders(
    token?: string | null,
    contentType: string = "application/x-www-form-urlencoded",
    config: AxiosRequestConfig = {}
  ) {
    const authorization = (() => {
      if (token === null) {
        return undefined;
      }

      return token || AuthToken.getAccessToken();
    })();

    return {
      ...this.defaultConfig,
      headers: {
        "Content-type": contentType,
        authorization,
      },
      ...config,
    };
  }

  public call(restObject: AxiosRequestConfig) {
    return validateAccessToken(this.ajax, restObject);
  }

  public get(url: string, config?: AxiosRequestConfig) {
    return this.call({
      url,
      method: "get",
      ...config,
    });
  }

  public post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.call({
      url,
      data,
      method: "post",
      ...config,
    });
  }

  public delete(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.call({
      url,
      method: "delete",
      data,
      ...config,
    });
  }

  public patch(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.call({
      url,
      method: "patch",
      data,
      ...config,
    });
  }

  private async successfulCall<T extends (response: any) => any>(
    method: "get" | "post" | "delete" | "patch",
    {
      url,
      data,
      param,
      query,
      config,
      applyHeaders = true,
      token,
      convertDataToSpecificType,
      validate,
      errorCallback,
      verifyErrorCondition,
      skipResponseDataValidation,
    }: SuccessfulApiProps<T>
  ): Promise<ReturnType<T> | null> {
    const contentType =
      data instanceof URLSearchParams ? undefined : "application/json";

    const appliedConfig = applyHeaders
      ? this.getConfigBySetHeaders(token, contentType, {
          params: query,
          ...config,
        })
      : { params: query, ...config };

    const appliedUrl = param ? `${url}/${param}` : url;

    try {
      let response = validateAxiosResponse(
        await this.call({
          url: appliedUrl,
          method,
          data,
          ...appliedConfig,
        }),
        { verifyErrorCondition, skipResponseDataValidation }
      );

      if (convertDataToSpecificType) {
        response = convertDataToSpecificType(
          new ConvertDataToSpecificType(response, (error) => {
            throw error;
          })
        );
      }

      if (response instanceof ConvertDataToSpecificType) {
        response = response.getResult();
      }

      if (validate) {
        return validate(response);
      }

      return response;
    } catch (error) {
      const appliedError = new APIError(error);

      errorCallback && errorCallback(appliedError);

      return null;
    }
  }

  /**
   * 성공한 요청에 대해서만 값을 리턴해줍니다.
   * 실패시 null값을 반환합니다. (이유: React-Query에서 undefined를 반환하면 error가 발생)
   * @param url: 주소.
   * @param param?: 파라미터를 추가합니다.
   * @param query?: 쿼리 스트링을 추가합니다.
   * @param config?: axios request config.
   * @param applyHeaders?: 기본적으로 headers를 세팅해줍니다. (기본값 true)
   * @param token?: 요청에 보낼 token을 세팅해줍니다. 만약 token없이 요청을 보낼 경우 null로 입력해주세요 (기본값 true)
   * @param covertDataToSpecificType?: 유효성 검사를 하기전 데이터를 원하는 타입으로 변환합니다.
   * @param validate?: 응답 데이터의 validate를 입력해주세요.
   * @param errorCallback?: error에 따라 수행할 명령들을 입력해주세요.
   * @param verifyErrorCondition?: http status에 따라 실패 조건을 작성합니다. (기본값 httpStatus !== 200)
   * @param skipResponseDataValidation?: 새 api spec에 맞춘 data schema형태로 값을 리턴합니다. (기본값 false)
   * }
   */
  public async successfulGet<T extends (response: any) => any>(
    props: Omit<SuccessfulApiProps<T>, "data">
  ) {
    return this.successfulCall("get", props);
  }

  /**
   * 성공한 요청에 대해서만 값을 리턴해줍니다.
   * 실패시 null값을 반환합니다. (이유: React-Query에서 undefined를 반환하면 error가 발생)
   * @param url: 주소;
   * @param data?: 보낼 데이터;
   * @param param?: 파라미터를 추가합니다.
   * @param query?: 쿼리 스트링을 추가합니다.
   * @param config?: axios request config;
   * @param applyHeaders?: 기본적으로 headers를 세팅해줍니다. (기본값 true);
   * @param token?: 요청에 보낼 token을 세팅해줍니다. 만약 token없이 요청을 보낼 경우 null로 입력해주세요 (기본값 true);
   * @param covertDataToSpecificType?: 유효성 검사를 하기전 데이터를 원하는 타입으로 변환합니다.
   * @param validate?: 응답 데이터의 validate를 입력해주세요.;
   * @param errorCallback?: error에 따라 수행할 명령들을 입력해주세요.;
   * @param verifyErrorCondition?: http status에 따라 실패 조건을 작성합니다. (기본값 httpStatus !== 200);
   * @param skipResponseDataValidation?: 새 api spec에 맞춘 data schema형태로 값을 리턴합니다. (기본값 false);
   * }
   */
  public async successfulPost<T extends (response: any) => any>(
    props: SuccessfulApiProps<T>
  ) {
    return this.successfulCall("post", props);
  }

  /**
   * 성공한 요청에 대해서만 값을 리턴해줍니다.
   * 실패시 null값을 반환합니다. (이유: React-Query에서 undefined를 반환하면 error가 발생)
   * @param url: 주소;
   * @param param?: 파라미터를 추가합니다.
   * @param query?: 쿼리 스트링을 추가합니다.
   * @param data?: 보낼 데이터;
   * @param config?: axios request config;
   * @param applyHeaders?: 기본적으로 headers를 세팅해줍니다. (기본값 true);
   * @param token?: 요청에 보낼 token을 세팅해줍니다. 만약 token없이 요청을 보낼 경우 null로 입력해주세요 (기본값 true);
   * @param covertDataToSpecificType?: 유효성 검사를 하기전 데이터를 원하는 타입으로 변환합니다.
   * @param validate?: 응답 데이터의 validate를 입력해주세요.;
   * @param errorCallback?: error에 따라 수행할 명령들을 입력해주세요.;
   * @param verifyErrorCondition?: http status에 따라 실패 조건을 작성합니다. (기본값 httpStatus !== 200);
   * @param skipResponseDataValidation?: 새 api spec에 맞춘 data schema형태로 값을 리턴합니다. (기본값 false);
   * }
   */
  public async successfulDelete<T extends (response: any) => any>(
    props: SuccessfulApiProps<T>
  ) {
    return this.successfulCall("delete", props);
  }

  /**
   * 성공한 요청에 대해서만 값을 리턴해줍니다.
   * 실패시 null값을 반환합니다. (이유: React-Query에서 undefined를 반환하면 error가 발생)
   * @param url: 주소;
   * @param param?: 파라미터를 추가합니다.
   * @param query?: 쿼리 스트링을 추가합니다.
   * @param data?: 보낼 데이터;
   * @param config?: axios request config;
   * @param applyHeaders?: 기본적으로 headers를 세팅해줍니다. (기본값 true);
   * @param token?: 요청에 보낼 token을 세팅해줍니다. 만약 token없이 요청을 보낼 경우 null로 입력해주세요 (기본값 true);
   * @param covertDataToSpecificType?: 유효성 검사를 하기전 데이터를 원하는 타입으로 변환합니다.
   * @param validate?: 응답 데이터의 validate를 입력해주세요.;
   * @param errorCallback?: error에 따라 수행할 명령들을 입력해주세요.;
   * @param verifyErrorCondition?: http status에 따라 실패 조건을 작성합니다. (기본값 httpStatus !== 200);
   * @param skipResponseDataValidation?: 새 api spec에 맞춘 data schema형태로 값을 리턴합니다. (기본값 false);
   * }
   */
  public async successfulPatch<T extends (response: any) => any>(
    props: SuccessfulApiProps<T>
  ) {
    return this.successfulCall("patch", props);
  }
}

export default LawsDaqAxios;
