import createURLSearchParams from "../utils/createURLSearchParams";
import axios from "../utils/lawsdaq-axios";
import Cookies from "js-cookie";

interface AppointLawyerServiceRegisterAPIProps {
  index: number | string;
  service: string;
  loan?: "true" | "false" | boolean;
  option?: string;
}

const appointLawyerServiceRegisterAPI = async ({
  index,
  service,
  loan = false,
  option = "",
}: AppointLawyerServiceRegisterAPIProps) => {
  const appliedLoan = typeof loan === "boolean" ? `${loan}` : loan;

  const accessToken = Cookies.get("accessToken");
  const params = createURLSearchParams({
    index,
    service,
    loan: appliedLoan,
  });

  if (service === "sa") {
    params.append("option", option);
  }

  const config = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      authorization: accessToken,
    },
  };

  try {
    const result = await axios.post(
      "/api/appointLawyer/serviceRegister",
      params,
      config
    );

    return result;
  } catch (e: any) {
    return e.response;
  }
};

export default appointLawyerServiceRegisterAPI;
