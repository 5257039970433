import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "TypeModule";

export const userSlice = createSlice({
  name: "users", // 리듀서 이름
  initialState: {
    id: undefined,
    info: "",
    link: "",
    name: "",
    phone: "",
    signCheck: 0,
  } as IUser,
  reducers: {
    loginLawyer(state, action: PayloadAction<IUser>): any {
      return action.payload;
      // return { ...state, userInfo: action.payload };
    },
    loginClient(state, action: PayloadAction<IUser>): any {
      return action.payload;
    },
  },
});

export const { loginLawyer, loginClient } = userSlice.actions;
export default userSlice.reducer;
