import axios from "axios";
import Cookies from "js-cookie";
import { openSuccessAlert } from "../../utils/modal/openModal";

// const onlyLawyerInfoAPI = async (accessToken) => {
const OnlyLawyerInfoCarrerUpdateAPI = async (
  education: [],
  career: [],
  licence: [],
  awards: [],
  professionalField: [],
  lawyerIdx: string
) => {
  const accessToken = Cookies.get("accessToken");
  const params = new URLSearchParams();
  // params.append("lawyerIdx", lawyerIdx);
  params.append("education", JSON.stringify(education));
  params.append("career", JSON.stringify(career));
  params.append("licence", JSON.stringify(licence));
  params.append("awards", JSON.stringify(awards));
  params.append("professionalField", JSON.stringify(professionalField));

  const config = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      authorization: accessToken,
    },
  };

  return axios
    .post("/api/lawyerInfo/historyUpdate", params, config)
    .then((res) => {
      const { status } = res;
      if (status === 200) {
        alert("내 정보수정이 완료되었습니다.");
        // openSuccessAlert("작성이 완료되었습니다.", () => {
        //   window.location.replace(`/re_OnlyHomeLawyer/${lawyerIdx}`);
        // });
        return status;
      }
      return res;
    })
    .catch((e) => console.error(e));
};

export default OnlyLawyerInfoCarrerUpdateAPI;
