import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import { TokensSchema } from "@lawsdaq-schema/tokens";
import AuthToken from "@lawsdaq-util/authToken";
import createURLSearchParams from "@lawsdaq-util/createURLSearchParams";

interface LawyerLoginProps {
  id: string;
  password: string;
}

export async function lawyerLoginAPI(
  { id, password }: LawyerLoginProps,
  errorCallback?: APIErrorCallback
) {
  const params = createURLSearchParams({ id, password });

  const response = await axios.successfulPost({
    url: "/api/user/lawyerLogin",
    data: params,
    validate: TokensSchema.parse,
    token: null,
    errorCallback,
  });

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;

  AuthToken.setAccessToken(accessToken);
  AuthToken.setRefreshToken(refreshToken);

  return response;
}
