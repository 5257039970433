import ModalBase from "./ModalBase";
import { ModalComponent } from "../../types";
import ConfirmContent from "./ConfirmContent";

const Leave: ModalComponent = ({ actionState, message }) => {
  return (
    <ModalBase type="dig">
      {/* // <ModalBase type="diagnosis"> */}
      <ModalBase.Header>
        <ModalBase.HeaderLeft>
          <ModalBase.Title>페이지 나가기</ModalBase.Title>
        </ModalBase.HeaderLeft>
      </ModalBase.Header>
      <ConfirmContent actionState={actionState} message={message}>
        <ModalBase.Main className="whitespace-pre-line">
          <ModalBase.InfoContent />
        </ModalBase.Main>
        <ModalBase.Footer>
          {/* <ModalBase.CancelButton /> */}
          <ModalBase.CancelDigButton />
          <ModalBase.MarginBox />
          {/* <ModalBase.ConfirmButton /> */}
          <ModalBase.ConfirmDigButton />
        </ModalBase.Footer>
      </ConfirmContent>
    </ModalBase>
  );
};

export default Leave;
