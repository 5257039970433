import { type EffectCallback, useEffect } from "react";

function setUseMount() {
  let isLoading = false;
  let isMounted = false;
  let result: ReturnType<EffectCallback>;

  function final() {
    if (!isLoading) {
      isLoading = true;
      setTimeout(() => {
        isLoading = false;
        isMounted = false;
      }, 50);
    }

    return typeof result === "function" ? result() : undefined;
  }

  return (callback: EffectCallback) => {
    useEffect(() => {
      if (!isMounted) {
        result = callback();
        isMounted = true;

        return final;
      }

      return final;
      // eslint-disable-next-line
    }, []);
  };
}

export { setUseMount };
