import { isLocal } from "./checkLocal";

/**
 * local에서만 error로그를 console에 나타냅니다.
 */
export function errorLog(error: any) {
  if (isLocal) {
    // eslint-disable-next-line
    console.error(error);
  }
}
