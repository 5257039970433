import {
  Alert,
  Confirm,
  Success,
  Warn,
  Error,
  Info,
  Async,
  Leave,
  Time,
  Dig,
} from "./modal-components";

import { ModalComponentFiber } from "./types";

const modalMetaList: ModalComponentFiber[] = [
  {
    name: "alert",
    component: Alert,
    defaultOptions: {
      required: true,
    },
  },
  {
    name: "confirm",
    component: Confirm,
    defaultOptions: {
      required: true,
      closeDelay: 800,
      position: (breakPoints) => (breakPoints > 425 ? "center" : "bottom"),
    },
  },
  {
    name: "success",
    component: Success,
    defaultOptions: {
      required: true,
    },
  },
  {
    name: "warn",
    component: Warn,
    defaultOptions: {
      duration: 400,
      required: true,
      position: "active-center",
    },
  },
  {
    name: "error",
    component: Error,
    defaultOptions: {
      duration: 400,
      required: true,
      position: "active-center",
    },
  },
  {
    name: "info",
    component: Info,
    defaultOptions: {
      required: true,
    },
  },
  {
    name: "async",
    component: Async,
    defaultOptions: {
      required: true,
      closeDelay: 800,
    },
  },
  // 여기서 부터는 추가된것
  {
    name: "time",
    component: Time,
    defaultOptions: {
      required: true,
      closeDelay: 2000, // 쿠폰 에러 알림창 시간 조정
    },
  },
  {
    name: "leave",
    component: Leave,
    defaultOptions: {
      required: true,
      closeDelay: 800,
      position: (breakPoints) => (breakPoints > 425 ? "center" : "bottom"),
    },
  },
  {
    name: "dig",
    component: Dig,
    defaultOptions: {
      required: true,
      // closeDelay: 800,
      closeDelay: 400,
      position: (breakPoints) => (breakPoints > 425 ? "center" : "bottom"),
    },
  },
  {
    name: "withdrawal",
    component: Leave,
    defaultOptions: {
      required: true,
      closeDelay: 800,
      position: (breakPoints) => (breakPoints > 425 ? "center" : "bottom"),
    },
  },
];

export default modalMetaList;
