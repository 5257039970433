import axios from "axios";

// const onlyLawyerInfoAPI = async (accessToken) => {
const OnlyLawyerInfoAPI = async (id: string) => {
  // Swagger 요구사항
  // Parameters
  // index * string (query)
  // 변호사 미니홈피 link : api/lawyerInfo/show?index={index}

  // const params = new URLSearchParams();
  // params.append("index", id);

  const config = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      // authorization: `bearer ${accessToken}`,
    },
    // params: { index: id },
  };

  return (
    axios
      // .get("/api/lawyerInfo/show", config)
      .get(`/api/lawyerInfo/show/?index=${id}`, config)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          return res;
        }
        return undefined;
      })
      .catch((e) => e.response)
  );

  // return axios
  //   .post("/api/lawyerInfo/show", params, config)
  //   .then((res) => {
  //     const { status } = res;
  //     if (status === 200) {
  //       console.log("service!!@@@@@@@@@@", res.data.data);
  //       return res.data;
  //     }
  //     return res;
  //   })
  //   .catch((e) => console.error(e));
};

export default OnlyLawyerInfoAPI;
