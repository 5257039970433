import { combineReducers } from "@reduxjs/toolkit";
import users from "./slices/users";
import summaries from "./slices/summaries";
import onlyLawyer from "./slices/onlyLawyer";
// import onlyLawyerWin from "./slices/onlyLawyerWin";
import stockUsers from "./slices/stockUsers";
import onlyLawyerMng from "./slices/onlyLawyerMng";
import userInfo from "./slices/userInfo";
import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";   // localStorage에 저장합니다.
import storage from "redux-persist/lib/storage/session"; // session Storage

const reducer = combineReducers({
  users,
  userInfo,
  summaries,
  onlyLawyer,
  // onlyLawyerWin,
  stockUsers,
  onlyLawyerMng,
});

const persistConfig = {
  key: "root",
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ["onlyLawyerMng"],
  // blacklist -> 그것만 제외합니다
};

export type ReducerType = ReturnType<typeof reducer>;
export default persistReducer(persistConfig, reducer);
