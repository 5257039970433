export function copyToClipboard(text: string) {
  // Clipboard API를 지원하는 경우
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(text).catch(() => {
      fallbackCopy(text);
    });
  } else {
    // Fallback for older browsers
    fallbackCopy(text);
  }
}

function fallbackCopy(text: string) {
  // Older browser or non-secure context, use document.execCommand
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    // eslint-disable-next-line no-console
  } finally {
    document.body.removeChild(textarea);
  }
}
