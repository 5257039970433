import { lazy, ReactNode, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from "../components/loading";
import { setPositionChildren } from "../libs/set-position-children";

// 홈,로그인
const ClientHome = lazy(() => import("../pages/client-home"));
const ReHomeLawyer = lazy(() => import("../re_containers/homeLawyer"));
const ReNewHomeLawyer = lazy(() => import("../pages/homeLawyer")); // 변호사 홈

const ReLogin = lazy(() => import("../re_containers/login"));
const ReFindId = lazy(() => import("../re_containers/loginFind"));

// 의뢰인 회원가입
const ReSignupClient = lazy(() => import("../re_containers/signupClient"));
const SignupClientAddInfo = lazy(
  () => import("../re_containers/signupClientAddInfo")
);
// 변호사회원가입
const ReSignupLawyerIntro = lazy(
  () => import("../re_containers/signupLawyerIntro")
);
const ReSignupLawyer = lazy(() => import("../re_containers/signupLawyer"));
const ReSignupLawyerPay = lazy(
  () => import("../re_containers/signupLawyerPay")
);
const ReSignupLawyerPayComplete = lazy(
  () => import("../re_containers/signupLawyerPayComplete")
);
// 내 사건 요약(사건요지서)
const ReSummaryMain = lazy(() => import("../re_containers/summaryMain"));
const ReSummaryWrite = lazy(() => import("../re_containers/summaryWrite"));
const ReSummaryEdit = lazy(() => import("../re_containers/summaryEdit"));
const ReSummaryEnd = lazy(() => import("../re_containers/summaryEnd"));
const ReSummaryPrintViewTest = lazy(
  () => import("../re_components/summaryPrintView")
);
const ReSummaryView = lazy(() => import("../re_containers/summaryView"));
const ReSummaryViewClient = lazy(
  () => import("../re_containers/summaryViewClient")
);

// 서비스 서브메인
{
  /* 사건 자문서 */
}
const ServiceJm = lazy(() => import("../pages/service-jm")); // 사건 자문서
const ServiceGs = lazy(() => import("../pages/service-gs")); // 최고 가성비 변호사 선임
const ServiceMc = lazy(() => import("../pages/service-mc")); // 유사사건 승소 변호사 선임
const ServiceSa = lazy(() => import("../pages/service-sa"));
const ServiceZd = lazy(() => import("../pages/service-zd"));
const LawsuitLoanMain = lazy(() => import("../re_containers/lawSuitLoanMain")); // 소송비용 금융연계
const LawsuitLoanLink = lazy(() => import("../re_containers/lawSuitLoanLink")); // 소송비용 금융연계
const LawsuitProspect = lazy(() => import("../re_containers/lawSuitProspect"));

// 옥션
const ReClientAuctionJm = lazy(
  () => import("../re_containers/myPage/client/auctionJm")
);
// 변호사 제안보기
const ReLawyerSuggestion = lazy(
  () => import("../re_containers/myPage/client/lawyerSuggestion")
);
// 의뢰인 마이페이지메뉴
const MySummaryList = lazy(
  () => import("../re_containers/myPage/client/mySummaryList")
); // 의뢰인 사건요지서목록
const RequestStatusClient = lazy(
  () => import("../re_containers/myPage/client/requestStatusClient")
); // 신청현황
const ReProcessStatusClient = lazy(
  () => import("../re_containers/myPage/client/processStatusClient")
); // 진행현황
const ReProcessStatusDetailClient = lazy(
  () => import("../re_containers/myPage/client/processStatusDetailClient")
); // 진행현황 상세보기
/*
const ReProcessStatusMatchDetailClient = lazy(() => import("../re_containers/myPage/client/processStatusMatchDetailClient")); // 진행현황 상세보기
*/
// 진행현황 금융신청
const MyLoanList = lazy(
  () => import("../re_containers/myPage/client/myLoanList")
);
// 정기권
const MyRegularMember = lazy(
  () => import("../re_containers/myPage/client/myRegularMember")
);
// 진행현황
const MyLoanDetail = lazy(
  () => import("../re_containers/myPage/client/myLoanDetail")
);
// 진행현황 금융신청
const MyDiagnosisList = lazy(
  () => import("../re_containers/myPage/client/myDiagnosisList")
);
// 진행현황 금융신청
const MyDiagnosisDetail = lazy(
  () => import("../re_containers/myPage/client/myDiagnosisDetail")
);
// 진행현황
const ReJamoonProcessStatusClient = lazy(
  () => import("../re_containers/myPage/client/jamoonProcessStatusClient")
);
// 완료목록
const ListComplete = lazy(
  () => import("../re_containers/myPage/client/listComplete")
);
// 완료목록 상세보기
const ShowComplete = lazy(
  () => import("../re_containers/myPage/client/showComplete")
);
// 의뢰인 내 정보수정
const UserInfoEdit = lazy(
  () => import("../re_containers/myPage/client/userInfoEdit")
);
const ClientChatList = lazy(() => import("../re_containers/clientChatList")); // 의뢰인 채팅목록
// 변호사 서비스 신청목록
const LawyerListAll = lazy(() => import("../pages/lawyerListAll")); // 사건수임
const LawyerListJm = lazy(() => import("../re_containers/lawyerListJm"));
const LawyerListGs = lazy(() => import("../re_containers/lawyerListGs"));
const LawyerListSa = lazy(() => import("../re_containers/lawyerListSa"));
const LawyerListZd = lazy(() => import("../re_containers/lawyerListZd"));
const LawyerListMc = lazy(() => import("../re_containers/lawyerListMc"));
// 변호사 제안 작성
const LawyerWriteSuggestion = lazy(
  () => import("../re_containers/lawyerWriteSuggestion")
);
// 변호사 제안목록보기
const LawyerCaseAppointment = lazy(
  () => import("../pages/lawyerCaseAppointment")
);
const LawyerListSuggestion = lazy(
  () => import("../re_containers/lawyerListSuggestion")
);
// 변호사 제안(1건)보기
const LawyerShowSuggestion = lazy(
  () => import("../re_containers/lawyerShowSuggestion")
);
// 변호사 자문요청보기
const LawyerListAllRequest = lazy(() => import("../pages/lawyerListRequest"));

const LawyerListJamoonRequest = lazy(
  () => import("../re_containers/lawyerListJamoonRequest")
);
const LawyerShowJamoonRequest = lazy(
  () => import("../re_containers/lawyerShowJamoonRequest")
);
const LawyerWriteJamoon = lazy(
  () => import("../re_containers/lawyerWriteJamoon")
);
const LawyerListComplete = lazy(
  () => import("../re_containers/lawyerListComplete")
);
const LawyerShowComplete = lazy(
  () => import("../re_containers/lawyerShowComplete")
);
const LawyerSummaryShow = lazy(
  () => import("../re_containers/lawyerSummaryShow")
);
// 변호사 선임요청보기
const LawyerListRequest = lazy(
  () => import("../re_containers/lawyerListRequest")
);
const LawyerShowRequest = lazy(
  () => import("../re_containers/lawyerShowRequest")
);
// 변호사 작성한 자문서보기(질/답)
const LawyerShowJamoon = lazy(
  () => import("../re_containers/lawyerShowJamoon")
);
// 의뢰인 자문서보기(질/답)
const ClientShowJamoon = lazy(
  () => import("../re_containers/clientShowJamoon")
);

// 변호사 채팅목록
const LawyerChatList = lazy(() => import("../re_containers/lawyerChatList"));
const LawyerChatDetail = lazy(
  () => import("../re_containers/lawyerChatDetail")
);
const ClientChatDetail = lazy(
  () => import("../re_containers/clientChatDetail")
);
// 변호사 간편상담
const LawyerBriefCounsel = lazy(
  () => import("../re_containers/lawyerBriefCounsel")
);

// 간편상담
const LawyerCounsel = lazy(
  // () => import("../re_containers/lawyerBriefCounsel")
  () => import("../pages/lawyerBriefCounsel")
);

const LawyerShowBriefCounsel = lazy(
  () => import("../re_containers/lawyerShowBriefCounsel")
);

// 간편상담 상세 페이지 (공용)
const CounselDetail = lazy(
  // () => import("../re_containers/lawyerShowBriefCounsel")
  () => import("../pages/lawyerBriefCounselDetail")
);

// 변호사 정보수정
const UserInfoEditLawyer = lazy(
  () => import("../re_containers/myPage/lawyer/userInfoEdit")
);

// 의뢰인 간편상담
const ClientBriefCounsel = lazy(
  () => import("../re_containers/clientBriefCounsel")
);

// 의뢰인 간편상담 글쓰기
const ClientWriteBriefCounsel = lazy(
  () => import("../re_containers/clientWriteBriefCounsel")
);

const ShowBriefCounsel = lazy(
  () => import("../re_containers/showBriefCounsel")
);

// 변호사 미니홈피 전용 페이지
const ReOnlyHomeLawyer = lazy(() => import("../re_containers/onlyHomeLawyer"));
const ReOnlyLoginLawyer = lazy(
  () => import("../re_containers/onlyLoginLawyer")
);
const ReOnlyCareerLawyer = lazy(
  () => import("../re_containers/onlyCareerLawyer")
);
const ReOnlyCareeEditLawyer = lazy(
  () => import("../re_containers/onlyCareerLawyer/Edit")
);
const ReOnlyWinLawyer = lazy(() => import("../re_containers/onlyWinLawyer"));
const ReOnlyWinEditList = lazy(
  () => import("../re_containers/onlyWinLawyer/onlyEditList")
);
const ReOnlyWinView = lazy(
  () => import("../re_containers/onlyWinLawyer/onlyViewDetails")
  // () =>
  //   import(
  //     "../re_components/onlyLawyerMainPage/components/third/WinningCase/viewDetails"
  //   )
);
const ReOnlyWinRegistration = lazy(
  () => import("../re_containers/onlyWinLawyer/onlyRegistration")
);
const ReOnlyCounsel = lazy(() => import("../re_containers/onlyCounselLawyer"));
const ReOnlyCounselEditList = lazy(
  () => import("../re_containers/onlyCounselLawyer/onlyEditList")
);
const ReOnlyCounselView = lazy(
  () => import("../re_containers/onlyCounselLawyer/onlyViewDetails")
);
const ReOnlyVideoLawyer = lazy(
  () => import("../re_containers/onlyVideoLawyer")
);
const ReOnlyVideoEditList = lazy(
  () => import("../re_containers/onlyVideoLawyer/onlyEditList")
);
const ReOnlyVideoView = lazy(
  () => import("../re_containers/onlyVideoLawyer/onlyViewDetails")
);
const ReOnlyVideoRegistration = lazy(
  () => import("../re_containers/onlyVideoLawyer/onlyRegistration")
);

const ReOnlyKnowledgeLawyer = lazy(
  () => import("../re_containers/onlyKnowledgeLawyer")
);
const ReOnlyknowledgeEditList = lazy(
  () => import("../re_containers/onlyKnowledgeLawyer/onlyEditList")
);
const ReOnlyknowledgeView = lazy(
  () => import("../re_containers/onlyKnowledgeLawyer/onlyViewDetails")
);
const ReOnlyknowledgeRegistration = lazy(
  () => import("../re_containers/onlyKnowledgeLawyer/onlyRegistration")
);

const ReOnlyOpinionLawyer = lazy(
  () => import("../re_containers/onlyOpinionLawyer")
);
const ReOnlyOpinionEditList = lazy(
  () => import("../re_containers/onlyOpinionLawyer/onlyEditList")
);
const ReOnlyOpinionView = lazy(
  () => import("../re_containers/onlyOpinionLawyer/onlyViewDetails")
);
// 마스터 관리 페이지
const MasterMain = lazy(() => import("../re_containers/masterMain"));
const MasterMember = lazy(() => import("../re_containers/masterMember"));
const MasterLawyer = lazy(() => import("../re_containers/masterLawyer"));
const Masterben = lazy(() => import("../re_containers/masterBen"));
const MasterContentsNotice = lazy(
  () => import("../re_containers/masterContentsNotice")
);
const MasterContentsNoticeView = lazy(
  () => import("../re_containers/masterContentsNotice/noticeViewDetails")
);
const MasterContentsNoticeR = lazy(
  () => import("../re_containers/masterContentsNotice/noticeRegist")
);
const MasterContentsCoalition = lazy(
  () => import("../re_containers/masterContentsCoalition")
);
const MasterContentsCoalitionView = lazy(
  () => import("../re_containers/masterContentsCoalition/CoalitionViewDetails")
);
const MasterContentsCoalitionR = lazy(
  () => import("../re_containers/masterContentsCoalition/CoalitionRegist")
);
const MasterContentsPartner = lazy(
  () => import("../re_containers/masterContentsPartner")
);
const MasterContentsPartnerR = lazy(
  () => import("../re_containers/masterContentsPartner/PartnerRegist")
);
const MasterHomeBanner = lazy(
  () => import("../re_containers/masterHomeBanner")
);
const MasterHomeIntro = lazy(() => import("../re_containers/masterHomeIntro"));
const MasterlitigationFinance = lazy(
  () => import("../re_containers/masterlitigationFinance")
);
const MasterPaymentDiagnose = lazy(
  () => import("../re_containers/masterPaymentDiagnose")
);
const MasterPaymentJamoon = lazy(
  () => import("../re_containers/masterPaymentJamoon")
);
const MasterPaymentStatistics = lazy(
  () => import("../re_containers/masterPaymentStatistics")
);
const MasterChart = lazy(() => import("../re_containers/masterChart"));
const StockMain = lazy(() => import("../re_containers/stockMain"));
const AdminLogin = lazy(() => import("../re_containers/admin/login"));
const PageNotFound = lazy(() => import("../re_containers/pageNotFound"));

const Notice = lazy(() => import("../re_containers/notice"));
const ShowNotice = lazy(() => import("../re_containers/showNotice"));
const PartnershipInquiry = lazy(
  () => import("../re_containers/partnershipInquiry")
);
const TeamMembers = lazy(() => import("../re_containers/teamMembers"));
const IndustryNews = lazy(() => import("../re_containers/industryNews"));
const Map = lazy(() => import("../re_containers/map"));
const ServiceSearch = lazy(() => import("../re_containers/serviceSearch"));

{
  /* 승소가능성진단 */
}
// 준비 데이터
const PrepareLitigation = lazy(() => import("../pages/prepare-litigation"));
const DigTutorialMobile = lazy(() => import("../pages/dig-tutorial-mobile"));
// 진행 데이터
const PrepareLawsuitPaper = lazy(
  () => import("../pages/prepare-lawsuit-paper")
);
const PrepareLawsuitWrite = lazy(
  () => import("../pages/prepare-lawsuit-write")
);
// 챗봇
const ChatAi = lazy(() => import("../pages/autoChat"));
const GuideMain = lazy(() => import("../pages/guide-main"));
const GuideMobileAndroid = lazy(() => import("../pages/guide-mobile-and"));
const GuideMobileAndroidApp = lazy(
  () => import("../pages/guide-mobile-and-app")
);
const GuideMobileIos = lazy(() => import("../pages/guide-mobile-ios"));
const TermsUse = lazy(() => import("../pages/terms-use"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));

interface RootRouterProps {
  children?: ReactNode;
}

/**
 * 해당 컴포넌트는 라우터를 관리하는 컴포넌트입니다.
 * 새로운 페이지를 추가할 때마다 해당 컴포넌트에 라우터를 추가해주세요.
 * 후에 해당 컴포넌트는 도메인 별로 분리될 예정입니다.
 * @returns
 */
const RootRouter = ({ children }: RootRouterProps) => {
  const { top, middle, bottom } = setPositionChildren(children);

  return (
    <Router>
      {top}
      <Suspense fallback={<Loading />}>
        <Switch>
          {/* 리뉴얼 버전 메인,회원가입,로그인,사건요지서 */}
          <Route path="/prepare" exact component={PrepareLitigation} />
          <Route path="/" exact component={ClientHome} />
          <Route
            path="/signupClient"
            exact
            render={(propsSignupClient: any) => (
              <ReSignupClient {...propsSignupClient} />
            )}
          />
          <Route
            path="/signupClientAddInfo"
            exact
            render={(propsSignupClientAddInfo: any) => (
              <SignupClientAddInfo {...propsSignupClientAddInfo} />
            )}
          />
          <Route
            path="/re_homeLawyer"
            exact
            render={(propsHomeLawyer: any) => (
              // <ReHomeLawyer {...propsHomeLawyer} />
              <ReNewHomeLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/renew_homeLawyer"
            exact
            render={(propsHomeLawyer: any) => (
              <ReNewHomeLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_login"
            exact
            render={(propsLogin) => <ReLogin {...propsLogin} />}
          />
          <Route
            path="/find/id"
            exact
            render={(propsLogin) => <ReFindId {...propsLogin} />}
          />
          <Route
            path="/find/password"
            exact
            render={(propsLogin) => <ReFindId {...propsLogin} />}
          />
          <Route
            path="/re_signupLawyerIntro"
            exact
            render={(propsSignupLawyerIntro: any) => (
              <ReSignupLawyerIntro {...propsSignupLawyerIntro} />
            )}
          />
          <Route
            path="/re_signupLawyer"
            exact
            render={(propSsignupLawyer: any) => (
              <ReSignupLawyer {...propSsignupLawyer} />
            )}
          />
          <Route
            path="/re_signupLawyerPay"
            exact
            render={(propsSignupLawyerPay: any) => (
              <ReSignupLawyerPay {...propsSignupLawyerPay} />
            )}
          />
          <Route
            path="/re_signupLawyerPayComplete"
            exact
            render={(propsSgnupLawyerPayComplete: any) => (
              <ReSignupLawyerPayComplete {...propsSgnupLawyerPayComplete} />
            )}
          />
          <Route
            path="/re_summaryMain"
            exact
            render={(propsSsummaryMain: any) => (
              <ReSummaryMain {...propsSsummaryMain} />
            )}
          />
          <Route
            path="/re_summaryWrite/:id"
            exact
            render={(propsSsummaryWrite: any) => (
              <ReSummaryWrite {...propsSsummaryWrite} />
            )}
          />
          <Route
            path="/re_summaryEdit"
            exact
            render={(propsSsummaryEdit: any) => (
              <ReSummaryEdit {...propsSsummaryEdit} />
            )}
          />
          <Route
            path="/re_summaryEnd"
            exact
            render={(propsSummaryEnd: any) => (
              <ReSummaryEnd {...propsSummaryEnd} />
            )}
          />
          <Route
            path="/re_summaryView"
            exact
            render={(propsSummaryView: any) => (
              <ReSummaryView {...propsSummaryView} />
            )}
          />
          <Route
            path="/re_summaryViewClient"
            exact
            render={(propsSummaryViewClient: any) => (
              <ReSummaryViewClient {...propsSummaryViewClient} />
            )}
          />
          {/* 자문서 */}
          <Route
            path="/re_jmMain"
            exact
            render={(propsJmMain: any) => <ServiceJm {...propsJmMain} />}
          />
          {/* 의뢰인 서비스 신청현황 자세히 보기 - 유사사건 */}
          <Route
            path="/re_clientAuctionJm"
            exact
            render={(propsClientAuctionJm: any) => (
              <ReClientAuctionJm {...propsClientAuctionJm} />
            )}
          />
          {/* 최저수임료 */}
          <Route
            path="/re_gsMain"
            exact
            render={(propsGsMain: any) => <ServiceGs {...propsGsMain} />}
          />
          {/* 소액 */}
          <Route
            path="/re_saMain"
            exact
            render={(propsSaMain: any) => <ServiceSa {...propsSaMain} />}
          />
          {/* 집단 */}
          <Route
            path="/re_zdMain"
            exact
            render={(propsZdMain: any) => <ServiceZd {...propsZdMain} />}
          />
          {/* 최고승소확률 */}
          <Route
            path="/re_mcMain"
            exact
            render={(propsMcMain: any) => <ServiceMc {...propsMcMain} />}
          />
          {/* 소송비용금융 */}
          <Route
            path="/lawsuitLoanMain"
            exact
            render={(propsLawsuitLoan: any) => (
              <LawsuitLoanMain {...propsLawsuitLoan} />
            )}
          />
          <Route
            path="/lawsuitLoanLink"
            exact
            render={(propsLawsuitLoan: any) => (
              <LawsuitLoanLink {...propsLawsuitLoan} />
            )}
          />

          <Route
            path="/lawsuitDiagnosisMain"
            exact
            component={PrepareLitigation}
          />
          <Route path="/digTutorial" exact component={DigTutorialMobile} />
          {/* 데이터 준비 제공 */}
          <Route path="/lawsuit-paper" exact component={PrepareLawsuitPaper} />
          {/* 소장 작성 */}
          <Route
            path="/lawsuitPaper/accusation"
            exact
            component={PrepareLawsuitWrite}
          />
          {/* 내용증명 작성 */}
          <Route
            path="/lawsuitPaper/contentsCertified"
            exact
            component={PrepareLawsuitWrite}
          />

          {/* 마이페이지-신청현황-의뢰인 */}
          <Route
            path="/mySummaryList"
            exact
            render={(propsMySummaryList: any) => (
              <MySummaryList {...propsMySummaryList} />
            )}
          />
          {/* 마이페이지-신청현황-의뢰인 */}
          <Route
            path="/requestStatusClient"
            exact
            render={(propsRequestStatusClient: any) => (
              <RequestStatusClient {...propsRequestStatusClient} />
            )}
          />
          {/* 마이페이지-변호사 제안보기-의뢰인 */}
          <Route
            path="/re_lawyerSuggestion"
            exact
            render={(propsLawyerSuggestion: any) => (
              <ReLawyerSuggestion {...propsLawyerSuggestion} />
            )}
          />
          {/* 마이페이지-진행현황(메인)-의뢰인 */}
          <Route
            path="/re_processStatusClient"
            exact
            render={(propsProcessStatusClient: any) => (
              <ReProcessStatusClient {...propsProcessStatusClient} />
            )}
          />
          {/* 마이페이지-진행현황(상세페이지)-의뢰인 */}
          <Route
            path="/re_processStatusDetailClient"
            exact
            render={(propsProcessStatusDetailClient: any) => (
              <ReProcessStatusDetailClient
                {...propsProcessStatusDetailClient}
              />
            )}
          />
          {/* 마이페이지-진행현황(매치 상세페이지)-의뢰인 */}
          {/* <Route
          path="/re_processStatusMatchClient"
          exact
          render={(propsProcessStatusMatchDetailClient: any) => (
            <ReProcessStatusMatchDetailClient
              {...propsProcessStatusMatchDetailClient}
            />
          )}
        /> */}
          {/* 마이페이지-금융신청목록-의뢰인 */}
          <Route
            path="/myLoanList"
            exact
            render={(propsMyLoanList: any) => (
              <MyLoanList {...propsMyLoanList} />
            )}
          />
          {/* 마이페이지-정기권-의뢰인 */}
          <Route
            path="/regular-member"
            exact
            render={(propsMyLoanList: any) => (
              <MyRegularMember {...propsMyLoanList} />
            )}
          />
          {/* 마이페이지-금융신청 상세보기-의뢰인 */}
          <Route
            path="/myLoanDetail"
            exact
            render={(propsMyLoanDetail: any) => (
              <MyLoanDetail {...propsMyLoanDetail} />
            )}
          />
          {/* 마이페이지-진행현황(자문)-의뢰인 */}
          <Route
            path="/re_jamoonProcessStatusClient"
            exact
            render={(propsJamoonProcessStatusClient: any) => (
              <ReJamoonProcessStatusClient
                {...propsJamoonProcessStatusClient}
              />
            )}
          />
          {/* 마이페이지-완료목록-의뢰인 */}
          <Route
            path="/listComplete"
            exact
            render={(propsListComplete: any) => (
              <ListComplete {...propsListComplete} />
            )}
          />
          {/* 마이페이지-완료목록 상세보기-의뢰인 */}
          <Route
            path="/showComplete"
            exact
            render={(propsShowComplete: any) => (
              <ShowComplete {...propsShowComplete} />
            )}
          />
          {/* 간편상담 */}
          <Route
            path="/clientBriefCounsel"
            exact
            render={(propsClientBriefCounsel: any) => (
              // <ClientBriefCounsel {...propsClientBriefCounsel} />
              <LawyerCounsel {...propsClientBriefCounsel} />
            )}
          />
          <Route
            path="/myBriefCounsel"
            exact
            render={(propsClientBriefCounsel: any) => (
              <ClientBriefCounsel {...propsClientBriefCounsel} />
            )}
          />
          <Route
            path="/clientWriteBriefCounsel"
            exact
            render={(propsClientWriteBriefCounsel: any) => (
              <ClientWriteBriefCounsel {...propsClientWriteBriefCounsel} />
            )}
          />
          <Route
            path="/showBriefCounsel"
            exact
            render={(propsShowBriefCounsel: any) => (
              <ShowBriefCounsel {...propsShowBriefCounsel} />
            )}
          />
          {/* AI 챗봇 */}
          <Route
            path="/chat-bot"
            exact
            render={(propsChatAi: any) => <ChatAi {...propsChatAi} />}
          />
          {/* 메인 가이드 */}
          <Route
            path="/guide-main"
            exact
            render={(propsGuideMain: any) => <GuideMain {...propsGuideMain} />}
          />
          <Route
            path="/guide-mobile-android"
            exact
            render={(propsGuideMain: any) => (
              <GuideMobileAndroid {...propsGuideMain} />
            )}
          />
          <Route
            path="/guide-mobile-android-app"
            exact
            render={(propsGuideMain: any) => (
              <GuideMobileAndroidApp {...propsGuideMain} />
            )}
          />
          <Route
            path="/guide-mobile-ios"
            exact
            render={(propsGuideMain: any) => (
              <GuideMobileIos {...propsGuideMain} />
            )}
          />
          {/* 마이페이지-승소가능성 진단목록-의뢰인 */}
          <Route
            path="/myDiagnosisList"
            exact
            render={(propsMyDiagnosisList: any) => (
              <MyDiagnosisList {...propsMyDiagnosisList} />
            )}
          />
          {/* 마이페이지-승소가능성 진단 상세보기-의뢰인 */}
          <Route
            path="/myDiagnosisDetail"
            exact
            render={(propsMyDiagnosisDetail: any) => (
              <MyDiagnosisDetail {...propsMyDiagnosisDetail} />
            )}
          />
          {/* 마이페이지-내 정보수정-의뢰인 */}
          <Route
            path="/userInfoEdit"
            exact
            render={(propsUserInfoEdit: any) => (
              <UserInfoEdit {...propsUserInfoEdit} />
            )}
          />
          {/* 마이페이지-내 정보수정-의뢰인 */}
          <Route
            path="/clientChatList"
            exact
            render={(propsClientChatList: any) => (
              <ClientChatList {...propsClientChatList} />
            )}
          />
          {/* 변호사 서비스 신청목록 */}
          <Route
            path="/lawyerListAll"
            exact
            render={(propsLawyerListJm: any) => (
              <LawyerListAll {...propsLawyerListJm} />
            )}
          />

          <Route
            path="/lawyerListJm"
            exact
            render={(propsLawyerListJm: any) => (
              <LawyerListJm {...propsLawyerListJm} />
            )}
          />
          <Route
            path="/lawyerListGs"
            exact
            render={(propsLawyerListGs: any) => (
              <LawyerListGs {...propsLawyerListGs} />
            )}
          />
          <Route
            path="/lawyerListSa"
            exact
            render={(propsLawyerListSa: any) => (
              <LawyerListSa {...propsLawyerListSa} />
            )}
          />
          <Route
            path="/lawyerListZd"
            exact
            render={(propsLawyerListZd: any) => (
              <LawyerListZd {...propsLawyerListZd} />
            )}
          />
          <Route
            path="/lawyerListMc"
            exact
            render={(propsLawyerListMc: any) => (
              <LawyerListMc {...propsLawyerListMc} />
            )}
          />

          <Route
            path="/lawyerWriteSuggestion"
            exact
            render={(propsLawyerWriteSuggestion: any) => (
              <LawyerWriteSuggestion {...propsLawyerWriteSuggestion} />
            )}
          />
          <Route
            path="/lawyerCaseAppointment"
            exact
            render={(propsLawyerListSuggestion: any) => (
              <LawyerCaseAppointment {...propsLawyerListSuggestion} />
            )}
          />
          <Route
            path="/lawyerListSuggestion"
            exact
            render={(propsLawyerListSuggestion: any) => (
              <LawyerListSuggestion {...propsLawyerListSuggestion} />
            )}
          />
          {/* 변호사 제안내용 상세 페이지 */}
          <Route
            path="/lawyerShowSuggestion"
            exact
            render={(propsLawyerShowSuggestion: any) => (
              <LawyerShowSuggestion {...propsLawyerShowSuggestion} />
            )}
          />
          <Route
            path="/lawyerListAllRequest"
            exact
            render={(propsLawyerListJamoonRequest: any) => (
              <LawyerListAllRequest {...propsLawyerListJamoonRequest} />
            )}
          />
          <Route
            path="/lawyerListJamoonRequest"
            exact
            render={(propsLawyerListJamoonRequest: any) => (
              <LawyerListJamoonRequest {...propsLawyerListJamoonRequest} />
            )}
          />
          <Route
            path="/lawyerShowJamoonRequest"
            exact
            render={(propsLawyerShowJamoonRequest: any) => (
              <LawyerShowJamoonRequest {...propsLawyerShowJamoonRequest} />
            )}
          />
          <Route
            path="/lawyerWriteJamoon"
            exact
            render={(propsLawyerWriteJamoon: any) => (
              <LawyerWriteJamoon {...propsLawyerWriteJamoon} />
            )}
          />
          <Route
            path="/lawyerListComplete"
            exact
            render={(propsLawyerListComplete: any) => (
              <LawyerListComplete {...propsLawyerListComplete} />
            )}
          />
          {/* 변호사 자문서 내용 상세보기 - 바로보기 , 내려받기 */}
          <Route
            path="/lawyerShowComplete"
            exact
            render={(propsLawyerShowComplete: any) => (
              <LawyerShowComplete {...propsLawyerShowComplete} />
            )}
          />

          {/* 신청 상세보기-변호사 */}
          <Route
            path="/lawyerSummaryShow"
            exact
            render={(propsLawyerSummaryShow: any) => (
              <LawyerSummaryShow {...propsLawyerSummaryShow} />
            )}
          />

          {/* 선임요청-변호사 */}
          <Route
            path="/lawyerListRequest"
            exact
            render={(propsLawyerListRequest: any) => (
              <LawyerListRequest {...propsLawyerListRequest} />
            )}
          />
          {/* 선임요청-상세보기 */}
          <Route
            path="/lawyerShowRequest"
            exact
            render={(propsLawyerShowRequest: any) => (
              <LawyerShowRequest {...propsLawyerShowRequest} />
            )}
          />
          {/* 자문서보기 */}
          <Route
            path="/lawyerShowJamoon"
            exact
            render={(propsLawyerShowJamoon: any) => (
              <LawyerShowJamoon {...propsLawyerShowJamoon} />
            )}
          />
          <Route
            path="/clientShowJamoon"
            exact
            render={(propsClientShowJamoon: any) => (
              <ClientShowJamoon {...propsClientShowJamoon} />
            )}
          />
          {/* 채팅-변호사 */}
          <Route
            path="/lawyerChatList"
            exact
            render={(propsLawyerChatList: any) => (
              <LawyerChatList {...propsLawyerChatList} />
            )}
          />
          <Route
            path="/lawyerChatDetail"
            exact
            render={(propsLawyerChatDetail: any) => (
              <LawyerChatDetail {...propsLawyerChatDetail} />
            )}
          />
          <Route
            path="/clientChatDetail"
            exact
            render={(propsClientChatDetail: any) => (
              <ClientChatDetail {...propsClientChatDetail} />
            )}
          />
          {/* 변호사 간편상담 */}
          <Route
            path="/lawyerCounsel"
            exact
            render={(propsLawyerBriefCounsel: any) => (
              <LawyerCounsel {...propsLawyerBriefCounsel} />
            )}
          />
          <Route
            path="/lawyerBriefCounsel"
            exact
            render={(propsLawyerBriefCounsel: any) => (
              <LawyerBriefCounsel {...propsLawyerBriefCounsel} />
            )}
          />
          <Route
            path="/lawyerShowBriefCounsel"
            exact
            render={(propsLawyerShowBriefCounsel: any) => (
              // <LawyerShowBriefCounsel {...propsLawyerShowBriefCounsel} />
              <CounselDetail {...propsLawyerShowBriefCounsel} />
            )}
          />
          {/* 변호사 정보수정 */}
          <Route
            path="/userInfoEditLawyer"
            exact
            render={(propsUserInfoEditLawyer: any) => (
              <UserInfoEditLawyer {...propsUserInfoEditLawyer} />
            )}
          />

          {/* 고객센터 */}
          <Route
            path="/notice"
            exact
            render={(propsNotice: any) => <Notice {...propsNotice} />}
          />
          <Route
            path="/showNotice"
            exact
            render={(propsShowNotice: any) => (
              <ShowNotice {...propsShowNotice} />
            )}
          />
          <Route
            path="/partnershipInquiry"
            exact
            render={(propsPartnershipInquiry: any) => (
              <PartnershipInquiry {...propsPartnershipInquiry} />
            )}
          />
          <Route
            path="/teamMembers"
            exact
            render={(propsTeamMembers: any) => (
              <TeamMembers {...propsTeamMembers} />
            )}
          />
          <Route
            path="/industryNews"
            exact
            render={(propsIndustryNews: any) => (
              <IndustryNews {...propsIndustryNews} />
            )}
          />
          <Route
            path="/map"
            exact
            render={(propsMap: any) => <Map {...propsMap} />}
          />
          <Route
            path="/serviceSearch"
            exact
            render={(propsSearch: any) => <ServiceSearch {...propsSearch} />}
          />

          <Route
            path="/ReSummaryPrintViewTest"
            exact
            render={(propsReSummaryPrintViewTest: any) => (
              <ReSummaryPrintViewTest {...propsReSummaryPrintViewTest} />
            )}
          />

          {/* 변호사 미니홈피 전용 */}
          <Route
            path="/re_OnlyHomeLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyHomeLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyLoginLawyer/:id"
            exact
            render={(propsLoginLawyer: any) => (
              <ReOnlyLoginLawyer {...propsLoginLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCareerLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyCareerLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCareerLawyer_edit/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyCareeEditLawyer {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyWinLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyWinLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyWinLawyer_list_edit"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyWinEditList {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyWinLawyer_registration/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyWinRegistration {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyWinLawyer_detail/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyWinView {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCounselLawyer/404-not-found"
            exact
            render={(propsHomeLawyer: any) => (
              <PageNotFound {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCounselLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyCounsel {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCounselLawyer_list/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyCounselEditList {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyCounselLawyer_detail/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyCounselView {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyVideoLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyVideoLawyer {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyVideoLawyer_list_edit/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyVideoEditList {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyVideoLawyer_detail/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyVideoView {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyVideoLawyer_registration/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyVideoRegistration {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyKnowledgeLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyKnowledgeLawyer {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyKnowledgeLawyer_list_edit/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyknowledgeEditList {...propsHomeLawyer} />
            )}
          />
          <Route
            path="/re_OnlyKnowledgeLawyer_registration/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyknowledgeRegistration {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyKnowledgeLawyer_detail/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyknowledgeView {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyOpinionLawyer/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyOpinionLawyer {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyOpinionLawyer_list_edit/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyOpinionEditList {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/re_OnlyOpinionLawyer_detail/:id"
            exact
            render={(propsHomeLawyer: any) => (
              <ReOnlyOpinionView {...propsHomeLawyer} />
            )}
          />

          <Route
            path="/masterMain"
            exact
            render={(propsMaster: any) => <MasterMain {...propsMaster} />}
          />
          <Route
            path="/masterMember/regular"
            exact
            render={(propsMaster: any) => <MasterMember {...propsMaster} />}
          />
          <Route
            path="/masterMember/lawyer"
            exact
            render={(propsMaster: any) => <MasterLawyer {...propsMaster} />}
          />
          <Route
            path="/masterMember/ben"
            exact
            render={(propsMaster: any) => <Masterben {...propsMaster} />}
          />
          <Route
            path="/masterContents/notice"
            exact
            render={(propsMaster: any) => (
              <MasterContentsNotice {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/notice/registration"
            exact
            render={(propsMaster: any) => (
              <MasterContentsNoticeR {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/notice/viewDetail"
            exact
            render={(propsMaster: any) => (
              <MasterContentsNoticeView {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/coalition"
            exact
            render={(propsMaster: any) => (
              <MasterContentsCoalition {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/coalition/viewDetail"
            exact
            render={(propsMaster: any) => (
              <MasterContentsCoalitionView {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/coalition/registration"
            exact
            render={(propsMaster: any) => (
              <MasterContentsCoalitionR {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/partner"
            exact
            render={(propsMaster: any) => (
              <MasterContentsPartner {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/partner/registration"
            exact
            render={(propsMaster: any) => (
              <MasterContentsPartnerR {...propsMaster} />
            )}
          />
          <Route
            path="/masterContents/homeBanner"
            exact
            render={(propsMaster: any) => <MasterHomeBanner {...propsMaster} />}
          />
          <Route
            path="/masterContents/MhomeBanner"
            exact
            render={(propsMaster: any) => <MasterHomeBanner {...propsMaster} />}
          />
          <Route
            path="/masterContents/intro"
            exact
            render={(propsMaster: any) => <MasterHomeIntro {...propsMaster} />}
          />
          <Route
            path="/masterContents/litigationFinance"
            exact
            render={(propsMaster: any) => (
              <MasterlitigationFinance {...propsMaster} />
            )}
          />
          <Route
            path="/masterPayment/diagnose"
            exact
            render={(propsMaster: any) => (
              <MasterPaymentDiagnose {...propsMaster} />
            )}
          />
          <Route
            path="/masterPayment/jamoon"
            exact
            render={(propsMaster: any) => (
              <MasterPaymentJamoon {...propsMaster} />
            )}
          />
          <Route
            path="/masterPayment/statistics"
            exact
            render={(propsMaster: any) => (
              <MasterPaymentStatistics {...propsMaster} />
            )}
          />
          <Route
            path="/masterChart/main"
            exact
            render={(propsMaster: any) => <MasterChart {...propsMaster} />}
          />
          <Route
            path="/stock/main"
            exact
            render={(propsMaster: any) => <StockMain {...propsMaster} />}
          />
          <Route
            path="/adminLogin"
            exact
            render={(propsAdmin: any) => <AdminLogin {...propsAdmin} />}
          />
          <Route
            path="/terms-use"
            exact
            render={(propsAdmin: any) => <TermsUse {...propsAdmin} />}
          />
          <Route
            path="/privace-policy"
            exact
            render={(propsAdmin: any) => <PrivacyPolicy {...propsAdmin} />}
          />

          {/* 404 페이지 */}
          <Route
            path="*"
            render={(propsPageNotFound: any) => (
              <PageNotFound {...propsPageNotFound} />
            )}
          />
          {middle}
        </Switch>
      </Suspense>
      {bottom}
    </Router>
  );
};

export default RootRouter;
