import { ModalCallback, openModal } from "@lawsdaq-lib/modal";
import { ModalDispatchOptions } from "@lawsdaq-lib/modal/types";

type OpenContentProps<T = any> = string | number | ModalDispatchOptions<T>;

function open<T = any>(
  name: string,
  options: OpenContentProps,
  callback?: ModalCallback
) {
  if (typeof options === "string" || typeof options === "number") {
    openModal(name, {
      modalKey: name,
      content: options,
      callback,
    });
    return;
  }

  openModal<T>(name, options);
}

export function openAlert(content: OpenContentProps, callback?: ModalCallback) {
  open("alert", content, callback);
}

export function openConfirm(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("confirm", content, callback);
}

export function openWarnAlert(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("warn", content, callback);
}

export function openSuccessAlert(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("success", content, callback);
}

export function openErrorAlert(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("error", content, callback);
}

export function openInfoAlert(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("info", content, callback);
}

export function openAsyncModal(content: OpenContentProps | ModalCallback) {
  if (typeof content === "function") {
    open("async", {}, content);

    return;
  }

  open("async", content);
}

// 여기서 부터는 추가된것

export function openTimeModal(content: OpenContentProps | ModalCallback) {
  if (typeof content === "function") {
    open("time", {}, content);

    return;
  }

  open("time", content);
}

export function openLeave(content: OpenContentProps, callback?: ModalCallback) {
  open("leave", content, callback);
}
export function openDig(content: OpenContentProps, callback?: ModalCallback) {
  open("dig", content, callback);
}

export function openWithDrawal(
  content: OpenContentProps,
  callback?: ModalCallback
) {
  open("withdrawal", content, callback);
}
