import { useEffect, useState } from "react";
import SocketContext from "./socketContext";
import { Socket, io } from "socket.io-client";

interface SocketProviderProps {
  serverPath?: string | null;
  nickname?: string | null;
  children: React.ReactNode;
}

/**
 * 해당 컴포넌트는 ContextAPI 사용하여 소켓 연결을 관리합니다.
 * @param param0
 * @returns SocketContext.Provider
 */
const SocketProvider = ({
  serverPath,
  nickname,
  children,
}: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (serverPath && nickname) {
      const newSocket = io(serverPath, {
        query: `nickname=${nickname}` as any,
        transports: ["websocket"],
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
        setSocket(null);
      };
    }

    if (socket) {
      // clean-up function: 소켓 연결을 끊습니다.
      socket.disconnect();
      setSocket(null);
    }

    // eslint-disable-next-line
    return () => {}
    // eslint-disable-next-line
  }, [serverPath, nickname]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
