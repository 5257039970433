import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import createURLSearchParams from "@lawsdaq-util/createURLSearchParams";
import { TokensSchema } from "@lawsdaq-schema/tokens";
import AuthToken from "@lawsdaq-util/authToken";

interface NonMemberLoginProps {
  phone: string;
  lawyerNum: string;
  name: string;
}

export async function nonMemberLoginAPI(
  { phone, lawyerNum, name }: NonMemberLoginProps,
  errorCallback?: APIErrorCallback
) {
  const params = createURLSearchParams({ phone, lawyerNum, name });

  const response = await axios.successfulPost({
    url: "/api/user/nonMemberLogin",
    data: params,
    validate: TokensSchema.parse,
    token: null,
    errorCallback,
  });

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;

  AuthToken.setAccessToken(accessToken);
  AuthToken.setRefreshToken(refreshToken);

  return response;
}
