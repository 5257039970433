import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ManageBtnProps {
  state: any;
  action: any;
}

export const stockSlice = createSlice({
  name: "manageButton", // 리듀서 이름
  initialState: {
    manageMode: true,
    editMode: true,
  },
  reducers: {
    manageBtn(state: any, action: any) {
      return action.payload;
    },
  },
});

export const { manageBtn }: any = stockSlice.actions;
export default stockSlice.reducer;
