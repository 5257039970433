import { z } from "zod";

const TokensSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
});

type Tokens = z.infer<typeof TokensSchema>;

export { TokensSchema, Tokens };
