function getURL() {
  const env = process?.env?.NODE_ENV;

  if (!env) {
    return "http://localhost:3000";
  }

  switch (env) {
    case "development":
      return "https://test.lawsdaq.com";
    case "production":
      return "https://lawsdaq.com";
    default:
      return "http://localhost:3000";
  }
}

function getBaseURL() {
  const { href } = window.location;

  if (!href) {
    return getURL();
  }

  const hrefList = href.split("/");

  if (!hrefList || !hrefList[2]) {
    return getURL();
  }

  const baseURL = `${hrefList[0]}//${hrefList[2]}`;

  return baseURL;
}

export default getBaseURL;
