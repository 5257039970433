import getBaseURL from "./getBaseURL";

function checkLocal() {
  if (getBaseURL() === "http://localhost:3000") {
    return true;
  }

  return false;
}

const isLocal = checkLocal();

export { isLocal, checkLocal };
