import ModalBase from "./ModalBase";
import { ModalComponent } from "../../types";
import styles from "../Modals.module.scss";
import { setUseMount } from "@lawsdaq-hook/useMount";

const useMount = setUseMount();

const Time: ModalComponent = ({ actionState, message, action, title }) => {
  useMount(() => {
    action(true);
  });

  return (
    <ModalBase type="alert">
      {title && (
        <ModalBase.Header>
          <ModalBase.HeaderLeft>
            <ModalBase.Title />
          </ModalBase.HeaderLeft>
        </ModalBase.Header>
      )}

      <div className={styles.confirmContents}>
        <div className={styles.checkmarkContainer}>
          <div
            className={`${styles.spinnerWrapper} ${
              actionState === "pending" || actionState === "initial"
                ? ""
                : styles.loaded
            }`}
            id="wrapper"
          >
            {actionState !== "error" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={styles.xmark}
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={styles.xmark}
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          {message && <p className={styles.message}>{message}</p>}
        </div>
      </div>
    </ModalBase>
  );
};

export default Time;
