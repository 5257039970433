import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import OnlyLawyerInfoAPI from "../../services/onlyLawyer/onlyLawyerInfo";
import OnlyLawyerInfoWinUpdate from "../../services/onlyLawyer/onlyLawyerInfoWinUpdate";
import OnlyLawyerInfoMainUpdate from "../../services/onlyLawyer/onlyLawyerInfoMainUpdate";
import OnlyLawyerInfoSubjectUpdateAPI from "../../services/onlyLawyer/onlyLawyerInfoSubjectUpdate";
import OnlyLawyerInfoFieldUpdateAPI from "../../services/onlyLawyer/onlyLawyerInfoFieldUpdate";
import OnlyLawyerInfoCarrerUpdateAPI from "../../services/onlyLawyer/onlyLawyerInfoCarrerUpdate";

// const initialState = [];
const initialState = {
  awards: [],
  carrer: [],
  education: [],
  id: undefined,
  lawyerIdx: undefined,
  licence: [],
  mainContents: "",
  mainTitle: "",
  majorField: [],
  professionalField: [],
  subContents: "",
  subTitle: "",
};

// const initialState = [];

export const getLaywerInfo = createAsyncThunk(
  "onlyLawyerInfo/get",
  async (ids: string) => {
    // 프론트 디스패치도 같은 형태로
    // async ({ ids }) => {
    const res = await OnlyLawyerInfoAPI(ids);
    return res.data;
  }
);

export const updateLaywerHeaderInfo = createAsyncThunk(
  "onlyLawyerHeader/update",
  async ({ mainTitle, mainContents, lawyerIdx }: any) => {
    const res = await OnlyLawyerInfoMainUpdate(
      mainTitle,
      mainContents
      // lawyerIdx
    );
    // @ts-ignore: Unreachable code error
    return res.data;
  }
);
export const updateLaywerSubInfo = createAsyncThunk(
  "onlyLawyerSubject/update",
  async ({ subTitle, subContents, image }: any) => {
    const res = await OnlyLawyerInfoSubjectUpdateAPI(
      subTitle,
      subContents,
      image
      // lawyerIdx
    );
    // @ts-ignore: Unreachable code error
    return res.data;
  }
);
export const updateLaywerFieldInfo = createAsyncThunk(
  "onlyLawyerField/update",
  async ({ dataList }: any) => {
    const res = await OnlyLawyerInfoFieldUpdateAPI(dataList);
    // @ts-ignore: Unreachable code error
    return res.data;
  }
);

export const updateLaywerCarr = createAsyncThunk(
  "onlyLawyerCarrer/update",
  async ({
    education,
    carrer,
    licence,
    awards,
    professionalField,
    lawyerIdx,
  }: // lawyerIdx,
  any) => {
    const res = await OnlyLawyerInfoCarrerUpdateAPI(
      education,
      carrer,
      licence,
      awards,
      professionalField,
      lawyerIdx
    );
    // @ts-ignore: Unreachable code error
    return res.data;
  }
);

// @ts-ignore: Unreachable code error
const onlyLaywerInfoSlice = createSlice({
  name: "lawyerInfo",
  initialState,
  // extraReducers: {
  //   // @ts-ignore: Unreachable code error
  //   [getLaywerInfo.fulfilled]: (state, action) => {
  //     return action.payload.lawyerInfo;
  //     //  return { ...state, ...action.payload };
  //     // return { ...state, ...action.payload.lawyerInfo };
  //     // return [action.payload.lawyerInfo];
  //     // return [...action.payload.lawyerInfo];
  //     // console.log("payload!!", action.payload.lawyerInfo);
  //   },
  //   // @ts-ignore: Unreachable code error
  //   [updateLaywerHeaderInfo.fulfilled]: (state, action) => {
  //     // console.log("state!!!", state);
  //     // console.log("action!!!", action);
  //     // const handleChange = (event) => {
  //     //   setValues({
  //     //     ...values,
  //     //     [event.target.name]: event.target.value,
  //     //   });
  //     // };
  //     return { ...state, ...action.payload };
  //     // const index = state.findIndex(
  //     //   (tutorial) => tutorial.layerIdx === action.payload.layerIdx
  //     // );
  //     // state[index] = {
  //     //   ...state[index],
  //     //   ...action.payload,
  //     // };
  //   },
  //   // @ts-ignore: Unreachable code error
  //   [updateLaywerSubInfo.fulfilled]: (state, action) => {
  //     // const handleChange = (event) => {
  //     //   setValues({
  //     //     ...values,
  //     //     [event.target.name]: event.target.value,
  //     //   });
  //     // };
  //     // return [action.payload.data];
  //     // return [...action.payload];
  //     return { ...state, ...action.payload };
  //   },
  //   // @ts-ignore: Unreachable code error
  //   [updateLaywerFieldInfo.fulfilled]: (state, action) => {
  //     // const handleChange = (event) => {
  //     //   setValues({
  //     //     ...values,
  //     //     [event.target.name]: event.target.value,
  //     //   });
  //     // };
  //     // return [action.payload.data];
  //     // return [...action.payload];
  //     return { ...state, ...action.payload };
  //   },
  //   // @ts-ignore: Unreachable code error
  //   [updateLaywerCarr.fulfilled]: (state, action) => {
  //     return { ...state, ...action.payload };
  //   },
  // },
  extraReducers: (builder) => {
    builder
      .addCase(getLaywerInfo.fulfilled, (state, action) => {
        return action.payload.lawyerInfo;
      })
      .addCase(updateLaywerSubInfo.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(updateLaywerFieldInfo.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(updateLaywerCarr.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

const { reducer } = onlyLaywerInfoSlice;
export default reducer;
