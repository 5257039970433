import "../../re_components/serviceSearch/SearchTable/re_pagers.scss";
import "../../re_components/summaryDetailShowLawyer/style.scss";
import "../../re_containers/summaryEnd/summary_slider.scss";
import "../../pages/lawyerListAll/re_pagers.scss";
import "../../pages/lawyerListAll/re_pagers_legacy.scss";

/**
 * 해당 컴포넌트는 legacy app 버전의 호환성을 위해 존재합니다.
 * 해당 컴포넌트의 내용이 전부 필요 없어지게 하는 것이 목표입니다.
 * 해당 컴포넌트는 src/App.tsx 에서 import 되어야 합니다.
 * import 해야하는 것들은 여기에 추가해주세요.
 * legacy에서만 사용되는 util 같은 것들도 해당 컴포넌에서 실행해주세요.
 * @returns null
 */
const LegacyCompatibility = () => {
  return null;
};

export default LegacyCompatibility;
