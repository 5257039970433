import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ClientUserInfo,
  LawyerUserInfo,
  UserInfo,
} from "../../schema/userInfo";

interface UserState {
  client: ClientUserInfo | null;
  lawyer: LawyerUserInfo | null;
  nickname: string | null;
}

const initialState: UserState = {
  client: null,
  lawyer: null,
  nickname: null,
};

export const userInfoSlice = createSlice({
  name: "userInfo", // 리듀서 이름
  initialState,
  reducers: {
    setUserInfo(state, { payload: userInfo }: PayloadAction<UserInfo>) {
      const filterdUserInfo = { ...userInfo, afterToken: undefined };

      if (filterdUserInfo.phone === undefined) {
        state.client = filterdUserInfo;
        state.lawyer = null;
      } else {
        let lawyerUserInfo = filterdUserInfo;

        if (filterdUserInfo.link === undefined) {
          lawyerUserInfo = { ...filterdUserInfo, link: "" };
        }

        if (filterdUserInfo.signCheck === undefined) {
          lawyerUserInfo = { ...filterdUserInfo, signCheck: false };
        }

        if (filterdUserInfo.phone === undefined) {
          lawyerUserInfo = { ...filterdUserInfo, phone: "" };
        }

        state.lawyer = lawyerUserInfo as LawyerUserInfo;
        state.client = null;
      }

      state.nickname = `${filterdUserInfo.info}_${filterdUserInfo.id}`;

      return state;
    },
    clearUserInfo() {
      return {
        client: null,
        lawyer: null,
        nickname: null,
      };
    },
  },
});

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
