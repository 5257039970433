import Cookies from "js-cookie";

const ACCESS_TOKEN = "accessToken" as const;
const REFRESH_TOKEN = "refreshToken" as const;
const LOGIN_USER_IDX = "loginUserIdx" as const;
const LOGIN_USER_TYPE = "loginUserType" as const;
const PREV_PATH_NAEM = "prevPathname" as const;
const ADMIN_ACCESS_TOKEN = "adminAccessToken" as const;
const LOGIN_USER_NICKANME = "nickname" as const;
const LAWYER_IDX = "lawyerIdx" as const;
const USER_LINK = "userLink" as const;

class AuthToken {
  static getAccessToken() {
    const accessToken = Cookies.get(ACCESS_TOKEN);

    return accessToken;
  }

  static getRefreshToken() {
    const refreshToken = Cookies.get(REFRESH_TOKEN);

    return refreshToken;
  }

  static getLoginUserIdx() {
    const loginUserIdx = Cookies.get(LOGIN_USER_IDX);

    return loginUserIdx;
  }

  static getLoginUserType() {
    const loginUserType = Cookies.get(LOGIN_USER_TYPE);

    return loginUserType;
  }

  static getAdminAccessToken() {
    const adminAccessToken = Cookies.get(ADMIN_ACCESS_TOKEN);

    return adminAccessToken;
  }

  static getLoginUserNickname() {
    return Cookies.get(LOGIN_USER_NICKANME);
  }

  static getLawyerIdx() {
    return Cookies.get(LOGIN_USER_IDX);
  }

  static getAppURL() {
    return process.env.REACT_APP_URL;
  }

  static getAllToken() {
    const accessToken = AuthToken.getAccessToken();
    const refreshToken = AuthToken.getRefreshToken();
    const loginUserIdx = AuthToken.getLoginUserIdx();
    const loginUserType = AuthToken.getLoginUserType();

    return {
      accessToken,
      refreshToken,
      loginUserIdx,
      loginUserType,
    };
  }

  static setAccessToken(accessToken: string) {
    Cookies.set(ACCESS_TOKEN, accessToken);
  }

  static setRefreshToken(refreshToken: string) {
    Cookies.set(REFRESH_TOKEN, refreshToken);
  }

  static setLoginUserIdx(loginUserIdx: string) {
    Cookies.set(LOGIN_USER_IDX, loginUserIdx);
  }

  static setLoginUserType(loginUserType: string) {
    Cookies.set(LOGIN_USER_TYPE, loginUserType);
  }

  static setLoginUserNickname(nickname: string) {
    Cookies.set(LOGIN_USER_NICKANME, nickname);
  }

  static setAdminAccessToken(adminAccessToken: string) {
    Cookies.set(ADMIN_ACCESS_TOKEN, adminAccessToken);
  }

  static setLawyerIdx(lawyerIdx: string) {
    Cookies.set(LAWYER_IDX, lawyerIdx);
  }

  static setUserLink(userLink: string) {
    Cookies.set(USER_LINK, userLink);
  }

  static removeAccessToken() {
    Cookies.remove(ACCESS_TOKEN);
  }

  static removeRefreshToken() {
    Cookies.remove(REFRESH_TOKEN);
  }

  static removeLoginUserIdx() {
    Cookies.remove(LOGIN_USER_IDX);
  }

  static removeLoginUserType() {
    Cookies.remove(LOGIN_USER_TYPE);
  }

  static removeLoginUserNickname() {
    Cookies.remove(LOGIN_USER_NICKANME);
  }

  static removePrevPathname() {
    localStorage.removeItem(PREV_PATH_NAEM);
  }

  static removeAdminAccessToken() {
    Cookies.remove(ADMIN_ACCESS_TOKEN);
  }

  static removeLawyerIdx() {
    Cookies.remove(LAWYER_IDX);
  }

  static removeUserLiLk() {
    Cookies.remove(USER_LINK);
  }

  static removeAllToken() {
    AuthToken.removeAccessToken();
    AuthToken.removeRefreshToken();
    AuthToken.removeLoginUserIdx();
    AuthToken.removeLoginUserType();
    AuthToken.removePrevPathname();
    AuthToken.removeLoginUserNickname();
    AuthToken.removeLawyerIdx();
    AuthToken.removeUserLiLk();
  }
}

export default AuthToken;
