import { errorLog } from "../log";

interface APIErrorType {
  httpStatus: number;
  status: string;
  result: any;
}

export class APIError implements APIErrorType {
  public httpStatus = 0;
  public status = "";
  public result: any = "";

  constructor(response: any) {
    this.logError(response).setResponse(response);
  }

  public logError(error: unknown) {
    if (error instanceof APIError) {
      return this;
    }

    errorLog(error);

    return this;
  }

  public setResponse(error: any) {
    if (error instanceof APIError) {
      this.httpStatus = error.httpStatus;
      this.status = error.status;
      this.result = error.result;

      return this;
    }

    if (!error) {
      this.result = "unknown";

      return this;
    }

    if (typeof error === "string") {
      this.result = error;

      return this;
    }

    if (typeof error !== "object") {
      this.result = "unknown";

      return this;
    }

    if (!error.response) {
      this.result = "unknown";

      return this;
    }

    const { response } = error;

    if (typeof response !== "object") {
      this.result = "unknown";
      return this;
    }

    this.httpStatus = response.status || 0;

    if (response.data) {
      if (typeof response.data === "string") {
        this.result = response.data;
      }

      if (typeof response.data !== "object") {
        this.result = "unknown";
      }

      const { status, result } = response.data;

      this.status = status || "";
      this.result = result || "unknown";
    }

    return this;
  }

  get(): APIErrorType {
    return {
      httpStatus: this.httpStatus,
      status: this.status,
      result: this.result,
    };
  }

  setHttpStatus(httpStatus: number) {
    this.httpStatus = httpStatus;

    return this;
  }

  setStatus(status: string) {
    this.status = status;

    return this;
  }

  setResult(result: any) {
    this.result = result;

    return this;
  }
}
