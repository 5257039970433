import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import createURLSearchParams from "@lawsdaq-util/createURLSearchParams";
import { TokensSchema } from "@lawsdaq-schema/tokens";
import AuthToken from "@lawsdaq-util/authToken";

/**
 * naver 인증을 통해 얻은 토큰으로 accessToken과 refreshToken을 발급 받습니다.
 * token을 발급받으면 cookie에 저장합니다.
 * @param token
 * @param errorCallback
 * @returns
 */
export async function loginNaverAPI(
  token: string,
  errorCallback?: APIErrorCallback
) {
  const data = createURLSearchParams({ token });

  const response = await axios.successfulPost({
    url: "/api/user/loginNaver",
    data,
    validate: TokensSchema.parse,
    errorCallback,
  });

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;

  AuthToken.setAccessToken(accessToken);
  AuthToken.setRefreshToken(refreshToken);

  return response;
}
