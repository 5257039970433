import axios from "axios";
import Cookies from "js-cookie";

// const onlyLawyerInfoAPI = async (accessToken) => {
const OnlyLawyerInfoMainUpdateAPI = async (
  mainTitle: string,
  mainContents: string
  // lawyerIdx: string
) => {
  const accessToken = Cookies.get("accessToken");
  const params = new URLSearchParams();
  // params.append("lawyerIdx", lawyerIdx);
  params.append("mainTitle", mainTitle);
  params.append("mainContents", mainContents);

  const config = {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      // "content-type": "multipart/form-data",
      authorization: accessToken,
    },
  };

  return axios
    .post("/api/lawyerInfo/mainUpdate", params, config)
    .then((res) => {
      const { status } = res;
      if (status === 200) {
        alert("내 정보수정이 완료되었습니다.");
        return status;
      }
      return res;
    })
    .catch((e) => console.error(e));
};

export default OnlyLawyerInfoMainUpdateAPI;
