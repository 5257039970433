import {
  loginClient,
  loginLawyer,
  setUserInfo,
  clearUserInfo,
  useAppDispatch,
  useAppSelector,
} from "@lawsdaq-store";
import { useCallback, useEffect } from "react";
import AuthToken from "@lawsdaq-util/authToken";
import {
  ClientUserInfo,
  LawyerUserInfo,
  UserInfo,
} from "@lawsdaq-schema/userInfo";
import { openWarnAlert } from "@lawsdaq-util/modal/openModal";
import { useHistory } from "react-router";
import Cookies from "js-cookie";

interface User<T extends string, P extends object> {
  type: T;
  userInfo: P;
  nickname: string;
}

export function useGetUser():
  | User<"client", ClientUserInfo>
  | User<"lawyer", LawyerUserInfo>
  | null {
  const { client, lawyer, nickname } = useAppSelector(
    (state) => state.userInfo
  );

  if (client) {
    return {
      type: "client",
      userInfo: client,
      nickname,
    } as User<"client", ClientUserInfo>;
  }

  if (lawyer) {
    return {
      type: "lawyer",
      userInfo: lawyer,
      nickname,
    } as User<"lawyer", LawyerUserInfo>;
  }

  return null;
}

export function useGetClientUser() {
  const { client } = useAppSelector((state) => state.userInfo);

  return client;
}

export function useGetLawyerUser() {
  const { lawyer } = useAppSelector((state) => state.userInfo);

  return lawyer;
}

function useCheckUserInfo<T>(userInfo: T) {
  const history = useHistory();

  const accessToken = Cookies.get("accessToken");

  // console.log(userInfo);
  // console.log("accessToken", accessToken);

  useEffect(() => {
    if (userInfo) {
      return;
    }
    if (!accessToken) {
      openWarnAlert({
        modalKey: "reauth",
        content: "로그인이 필요한 서비스입니다.",
        callback: () => {
          localStorage.setItem("prevPathname", window.location.pathname);
          history.push("/re_login");
        },
      });
    }
  }, [userInfo, history]);

  return userInfo;
}

export function useGetUserWithReauth() {
  return useCheckUserInfo(useGetUser());
}

export function useGetClientUserWithReauth() {
  return useCheckUserInfo(useGetClientUser());
}

export function useGetLawyerUserWithReauth() {
  return useCheckUserInfo(useGetLawyerUser());
}

export function useSetUserInfo() {
  const dispatch = useAppDispatch();
  return useCallback(
    (userInfo: UserInfo) => {
      dispatch(setUserInfo(userInfo));
    },
    [dispatch]
  );
}

export function useClearUserInfo() {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(clearUserInfo());
  }, [dispatch]);
}

export function useSignIn() {
  const dispatch = useAppDispatch();

  return useCallback(
    (userInfo: UserInfo) => {
      dispatch(setUserInfo(userInfo));

      /**
       * TODO userInfo reducer로 마이그레이션이 된 후 삭제될 코드입니다.
       */
      const { id, info, name, link, phone, signCheck } = userInfo;

      const newNickname = `${info}_${id}`;

      AuthToken.setLoginUserNickname(newNickname);
      AuthToken.setLoginUserType(info);
      AuthToken.setLoginUserIdx(`${id}`);

      if (info === "lawyer") {
        AuthToken.setLawyerIdx(`${id}`);
        AuthToken.setUserLink(link || "");
        dispatch(
          loginLawyer({
            id,
            info,
            link,
            name,
            phone: phone || "",
            signCheck: signCheck ? 1 : 0,
          })
        );
      } else {
        dispatch(loginClient({ id, info, name, phone: phone || "" }));
      }
    },
    [dispatch]
  );
}

export function useSignOut() {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(clearUserInfo());
    AuthToken.removeAllToken();

    /**
     * TODO userInfo reducer로 마이그레이션이 된 후 삭제될 코드입니다.
     */

    dispatch(
      loginLawyer({
        id: undefined,
        info: "",
        link: "",
        name: "",
        phone: "",
        signCheck: undefined,
      })
    );
    dispatch(
      loginClient({
        id: undefined,
        info: "",
        link: "",
        name: "",
        phone: "",
        signCheck: undefined,
      })
    );
  }, [dispatch]);
}
