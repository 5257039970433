import axios from "axios";
import Cookies from "js-cookie";

// const onlyLawyerInfoAPI = async (accessToken) => {
const OnlyLawyerInfoSubjectUpdateAPI = async (
  subTitle: string,
  subContents: string,
  image: any
  // lawyerIdx: string
) => {
  const accessToken = Cookies.get("accessToken");
  const formData = new FormData();

  if (typeof image !== "string") {
    const object: any = {};

    image?.forEach((value: string, key: string) => {
      object[key] = value;
    });

    const dateTime = new Date();
    // const filename: any = dateTime.getTime();
    // formData.append("filename", filename);
    formData.append("image", object.file);
  }

  // formData.append("lawyerIdx", lawyerIdx);
  formData.append("subTitle", subTitle);
  formData.append("subContents", subContents);

  const config = {
    headers: {
      // "Content-type": "application/x-www-form-urlencoded",
      "content-type": "multipart/form-data",
      authorization: accessToken,
    },
  };

  return axios
    .post("/api/lawyerInfo/mainUpdate", formData, config)
    .then((res) => {
      const { status } = res;
      if (status === 200) {
        alert("내 정보수정이 완료되었습니다.");
        return status;
      }
      return res;
    })
    .catch((e) => console.error(e));
};

export default OnlyLawyerInfoSubjectUpdateAPI;
