import { useEffect, useMemo } from "react";
import {
  ModalBase,
  ModalCallback,
  ModalComponentProps,
  ModalConfirmContent,
  ModalFC,
} from "../../../libs/modal";
import { useHistory } from "react-router-dom";
import { ValidationSchema } from "../../../libs/modal/utils/validate/types";
import { validate } from "../../../libs/modal/utils/validate";
import appointLawyerServiceRegisterAPI from "../../../services/appointLawyerServiceRegister";

interface ServiceRegistrationInfo {
  summaryId: number;
  serviceTitle: string;
  serviceType: string;
}

interface RegisterServiceProps
  extends ServiceRegistrationInfo,
    ModalComponentProps {}

const RegisterService = ({
  summaryId,
  serviceTitle,
  serviceType,
  actionState,
  message,
  stateManager,
}: RegisterServiceProps) => {
  const history = useHistory();

  const registServiceGs: ModalCallback = async (
    confirm,
    { pending, success, error }
  ) => {
    if (confirm !== true) {
      return;
    }

    pending();

    try {
      const res = await appointLawyerServiceRegisterAPI({
        index: summaryId,
        service: serviceType,
        option: "",
        loan: true,
      });

      if (res.status === 200) {
        success("성공적으로 신청되었습니다!", () => {
          if (history && history.push) {
            history.push("/requestStatusClient");
          }
        });
      } else if (res.status === 400) {
        error("이미 신청한 요지서입니다.");
      } else {
        error("다시 시도해주세요.");
      }
    } catch (e) {
      error("알 수 없는 에러 입니다.");
    }
  };

  useEffect(() => {
    stateManager.setCallback(registServiceGs);
    // eslint-disable-next-line
  }, [serviceType, stateManager]);

  return (
    <ModalBase type="confirm">
      <ModalBase.Header>
        <ModalBase.HeaderLeft>
          <ModalBase.Title>{serviceTitle || "서비스 신청"} </ModalBase.Title>
        </ModalBase.HeaderLeft>
      </ModalBase.Header>
      <ModalConfirmContent actionState={actionState} message={message}>
        <ModalBase.Main>
          <ModalBase.InfoContent as="h3" />
        </ModalBase.Main>
        <ModalBase.Footer>
          <ModalBase.ConfirmButton>신청하기</ModalBase.ConfirmButton>
          <ModalBase.MarginBox />
          <ModalBase.CancelButton />
        </ModalBase.Footer>
      </ModalConfirmContent>
    </ModalBase>
  );
};

const schemaOfRegisterService: ValidationSchema<ServiceRegistrationInfo> = {
  summaryId: {
    type: "number",
  },
  serviceTitle: {
    type: "string",
  },
  serviceType: {
    type: "string",
  },
};

const RegisterServiceWrapper: ModalFC<ServiceRegistrationInfo> = (props) => {
  const {
    stateController: { final },
    payload,
  } = props;

  const serviceRegistrationInfo = useMemo(() => {
    return validate(payload, schemaOfRegisterService);
  }, [payload]);

  useEffect(() => {
    if (!serviceRegistrationInfo) {
      final();
    }
    // eslint-disable-next-line
  }, [serviceRegistrationInfo]);

  return serviceRegistrationInfo ? (
    <RegisterService {...props} {...serviceRegistrationInfo} />
  ) : null;
};

export default RegisterServiceWrapper;
