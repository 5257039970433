interface GetURLSearchParamsProps {
  [p: string]: string | number;
}

function createURLSearchParams(data?: GetURLSearchParamsProps) {
  const params = new URLSearchParams();

  if (data) {
    for (const [key, value] of Object.entries(data)) {
      params.append(key, `${value}`);
    }
  }

  return params;
}

export default createURLSearchParams;
