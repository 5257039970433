import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingImgBox}>
          <img className={styles.loadingImg} src="/logo.png" alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
