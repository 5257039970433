import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import createURLSearchParams from "@lawsdaq-util/createURLSearchParams";
import { TokensSchema } from "@lawsdaq-schema/tokens";
import AuthToken from "@lawsdaq-util/authToken";

interface RegisterKakaoProps {
  kakaoInfoId: number;
  name: string;
  email?: string;
  phone: string;
  // ad: boolean;
  birth?: string | null;
  genders?: string | null | undefined;
  adEmail: number;
  adMessage: number;
}

/**
 * kakao 을 통해 얻은 토큰으로 accessToken과 refreshToken을 발급 받습니다.
 * token을 발급받으면 cookie에 저장합니다.
 * @param code
 * @param errorCallback
 * @returns
 */
export async function registerKakaoAPI(
  // { kakaoInfoId, name, email, phone, ad }: RegisterKakaoProps,
  {
    kakaoInfoId,
    name,
    email,
    phone,
    birth,
    genders,
    adEmail,
    adMessage,
  }: RegisterKakaoProps,
  errorCallback?: APIErrorCallback
) {
  const data = createURLSearchParams({
    id: kakaoInfoId,
    name,
    phone,
    // ad: `${ad ? 1 : 0}`,
    // birth: `${birth || "null"}`,
    birth: `${birth === undefined ? null : birth}`,
    gender: `${genders === undefined ? null : genders}`,
    adEmail,
    adMessage,
  });

  if (email) {
    data.append("email", email);
  }

  // console.log("birth", birth);
  // console.log("genders", genders);
  // console.log(data);

  const response = await axios.successfulPost({
    url: "/api/user/registerKakao",
    data,
    validate: TokensSchema.parse,
    errorCallback,
  });

  if (!response) {
    return null;
  }

  const { accessToken, refreshToken } = response;

  AuthToken.setAccessToken(accessToken);
  AuthToken.setRefreshToken(refreshToken);

  return response;
}
