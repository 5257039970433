import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISummary } from "TypeModule";

export const summarySlice: any = createSlice({
  name: "summaries", // 리듀서 이름
  initialState: {
    id: undefined,
    title: "",
    address: "",
    name: "",
    case: "",
    type1: "",
    type2: "",
    content: "",
    appointLawyer: undefined,
    clientIdx: undefined,
    createdAt: "",
    updatedAt: "",
  },
  reducers: {
    setSummary(state, action: PayloadAction<ISummary>): any {
      return action.payload;
    },
  },
});

export const { setSummary } = summarySlice.actions;
export default summarySlice.reducer;
