import { AxiosRequestConfig } from "axios";
import updateToken from "./updateToken";
import AuthToken from "../authToken";

async function validateAccessToken(
  call: (ajaxInfo: AxiosRequestConfig) => Promise<unknown>,
  ajaxInfo: AxiosRequestConfig
) {
  try {
    const response: any = await call(ajaxInfo);

    return Promise.resolve(response);
  } catch (error: any) {
    if (
      !error ||
      typeof error !== "object" ||
      !error.response ||
      !error.response.status
    ) {
      // eslint-disable-next-line
      console.error("알 수 없는 error.", error);
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        response: {
          status: 500,
          data: "알 수 없는 에러",
        },
      });
    }

    if (!ajaxInfo.headers?.authorization) {
      const refreshToken = AuthToken.getRefreshToken();

      if (!refreshToken) {
        return Promise.reject(error);
      }
    }

    const {
      response: { status },
    } = error;

    if (status !== 401) {
      return Promise.reject(error);
    }

    const accessToken = await updateToken();

    if (accessToken) {
      const newAjaxInfo: AxiosRequestConfig = {
        ...ajaxInfo,
        headers: {
          ...ajaxInfo.headers,
          authorization: accessToken,
        },
      };

      return call(newAjaxInfo);
    }

    return Promise.reject(error);
  }
}

export default validateAccessToken;
