type ArrayCallback = {
  type: "array";
};

type ObjectCallback = {
  type: "object";
  key: string | number | symbol;
};

type ApplyCallback = {
  type: "apply";
  merge: boolean;
  converter: (data: any) => any;
};

type CallbackType = ArrayCallback | ApplyCallback | ObjectCallback;

function convert(data: any, index: number, callbackList: CallbackType[]): any {
  const callback = callbackList[index];

  if (!callback) {
    return data;
  }

  if (!data) {
    throw new Error("data가 없습니다.");
  }

  if (callback.type === "array") {
    if (!Array.isArray(data)) {
      throw new Error("data가 배열이 아닙니다.");
    }

    return data.map((value: any) => convert(value, index + 1, callbackList));
  }

  if (typeof data !== "object" || Array.isArray(data)) {
    throw new Error("data가 객체가 아닙니다.");
  }

  if (callback.type === "object") {
    const value = data[callback.key];

    if (!value) {
      throw new Error("key가 없습니다.");
    }

    const convertedValue: any = convert(value, index + 1, callbackList);

    return {
      ...data,
      [callback.key]: convertedValue,
    };
  }

  const convertedData = callback.converter(data);

  if (
    !convertedData ||
    typeof convertedData !== "object" ||
    Array.isArray(convertedData)
  ) {
    throw new Error("converter가 객체가 아닙니다.");
  }

  if (callback.merge) {
    return convert(
      {
        ...data,
        ...convertedData,
      },
      index + 1,
      callbackList
    );
  }

  return convert(convertedData, index + 1, callbackList);
}

/**
 * ConvertDataToSpecificType 클래스는 주어진 데이터를 특정 타입으로 변환하는 로직을 담당합니다.
 */
class ConvertDataToSpecificType {
  private callbackList: CallbackType[] = [];

  constructor(
    private data: any,
    private errorCallback?: (error: any) => void
  ) {}

  getResult() {
    if (!this.callbackList.length) {
      return this.data;
    }

    try {
      this.data = convert(this.data, 0, this.callbackList);

      return this.data;
    } catch (error) {
      this.errorCallback && this.errorCallback(error);

      return undefined;
    }
  }

  traverseArray() {
    this.callbackList.push({ type: "array" });

    return this;
  }

  traverseObject(key: string | number | symbol) {
    this.callbackList.push({ type: "object", key });

    return this;
  }

  applyConversion(converter: (data: any) => any, merge: boolean = true) {
    this.callbackList.push({ type: "apply", converter, merge });

    return this;
  }
}

export default ConvertDataToSpecificType;
