import axios, { APIErrorCallback } from "@lawsdaq-util/lawsdaq-axios";
import { UserInfoSchema } from "@lawsdaq-schema/userInfo";
import AuthToken from "@lawsdaq-util/authToken";

/**
 * 인증에 성공하여 새 accessToken을 발급 받으면 cookie에 저장합니다.
 *
 * @param errorCallback
 * @returns
 */
export async function getUserInfoAPI(errorCallback?: APIErrorCallback) {
  const userInfo = await axios.successfulGet({
    url: "/api/user/userInfo",
    validate: UserInfoSchema.parse,
    errorCallback,
  });

  if (!userInfo) {
    return null;
  }

  // console.log("userInfo", userInfo);
  // console.log("userInfo.afterToken", userInfo.afterToken); // 서버에서 응답해준 토큰

  AuthToken.setAccessToken(userInfo.afterToken);

  return userInfo;
}
