import AuthToken from "../authToken";
import axios, { AxiosResponse } from "axios";
import getBaseURL from "../getBaseURL";
import { openModal } from "@lawsdaq-lib/modal";

const URL = getBaseURL();
let isAlert = false;
let isUpdating = false;

async function userAccessTokenAPI() {
  const refreshToken = AuthToken.getRefreshToken();
  const baseURL = URL || getBaseURL();

  return axios.get(`${baseURL}/api/user/accessToken`, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      authorization: refreshToken,
    },
  });
}

async function getUserInfo() {
  const accessToken = AuthToken.getAccessToken();
  const baseURL = URL || getBaseURL();

  return axios
    .get(`${baseURL}/api/user/userInfo`, {
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        authorization: accessToken,
      },
    })
    .then((response) => response)
    .catch((error) => {
      // eslint-disable-next-line
      console.log(error, "updateToken.ts line: 35");
      return error;
    });
}

function getAuthErrorMessage(error: any) {
  if (
    !error ||
    typeof error !== "object" ||
    !error.response ||
    !error.response.data
  ) {
    return "다시 로그인 해주세요.";
  }

  const {
    response: { data },
  } = error;

  switch (data) {
    case "JsonWebTokenError":
      return "다른 기기에서 로그인하셨거나 비정상적인 접근이 있을 수 있습니다. 다시 로그인 해주세요.";
    case "TokenExpiredError":
      return "로그인한지 오랜 시간이 지나 다시 로그인 해주세요.";
    default:
      return "다시 로그인 해주세요.";
  }
}

function actionFailedAuth(response: any = { data: "로그인 해주세요." }) {
  const message = getAuthErrorMessage(response);

  AuthToken.removeAllToken();

  if (!isAlert) {
    isAlert = true;

    openModal("warn", {
      content: message,
      callback: () => {
        window.location.replace("/re_login");
        isAlert = false;
      },
    });
  }

  isUpdating = false;
}

async function updateToken(): Promise<string | null> {
  const refreshToken = AuthToken.getRefreshToken();

  try {
    if (!refreshToken) {
      actionFailedAuth();

      return null;
    }

    const responseByUserAccessTokenAPI = await userAccessTokenAPI();

    if (!responseByUserAccessTokenAPI.data) {
      actionFailedAuth(responseByUserAccessTokenAPI);

      return null;
    }

    if (
      responseByUserAccessTokenAPI === null ||
      typeof responseByUserAccessTokenAPI !== "object"
    ) {
      return null;
    }

    const { data } = responseByUserAccessTokenAPI as AxiosResponse;

    if (!data) {
      actionFailedAuth({ data: "다시 로그인 해주세요." });

      return null;
    }

    AuthToken.setAccessToken(data.accessToken);

    const accessToken = AuthToken.getAccessToken();

    if (!accessToken) {
      actionFailedAuth({ data: "다시 로그인 해주세요." });

      return null;
    }

    const responseByGetUserInfoAPI = await getUserInfo();

    if (!responseByGetUserInfoAPI.data) {
      actionFailedAuth(responseByGetUserInfoAPI);

      return null;
    }

    return accessToken;
  } catch (error) {
    actionFailedAuth(error);

    return null;
  }
}

function pending(resolve: (value: unknown) => void) {
  // eslint-disable-next-line
  setTimeout(function () {
    if (isUpdating) {
      pending(resolve);
    } else {
      const accessToken = AuthToken.getAccessToken();
      resolve(accessToken || null);
    }
  }, 100);
}

function getAccessToken() {
  return new Promise((resolve) => {
    pending(resolve);
  });
}

async function updateTokenHandler() {
  if (isUpdating) {
    const accessToken = await getAccessToken();

    return accessToken as string | null;
  }

  isUpdating = true;

  const result = await updateToken();

  isUpdating = false;

  return result;
}

export default updateTokenHandler;
