import { ButtonHTMLAttributes, MouseEvent } from "react";
import useModalOptions from "../hooks/useModalOptions";

interface ModalCancelButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

const ModalDigCancelBtn = ({
  onClick,
  children,
  ...restProps
}: ModalCancelButtonProps) => {
  const options = useModalOptions();

  if (!options) {
    return null;
  }

  const { action, cancelContent } = options;

  const onClickCancel = (e: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(e);
    action(false);
  };

  return (
    <button {...restProps} onClick={onClickCancel} type="button">
      {cancelContent || children || "나중에 하기"}
    </button>
  );
};

export default ModalDigCancelBtn;
